import {
    IDetailData,
    IFaceOff,
    IFaceOffLocation,
    IFaceOffMapPosition,
    IFaceOffPlayer,
    IPlayer,
    IShot,
} from "../Types";
import { getPercent } from "./common";
import { getRostedIds, isInTime } from "./matches";
import { getTeamShots } from "./shots";

export const getPlayerFaceOff = (playerId: string, faceOffs: IFaceOff[]) => {
    return [
        ...faceOffs.filter(
            (faceOff) =>
                `${faceOff.winnerId.id}` === playerId ||
                `${faceOff.loserId.id}` === playerId
        ),
    ].sort((a: IFaceOff, b: IFaceOff) => a.time - b.time);
};

export const getPlayerFaceOffCount = (
    playerId: string,
    faceOffs: IFaceOff[]
) => {
    return getPlayerFaceOff(playerId, faceOffs).length;
};

export const getTeamFaceOffWinner = (
    roster: IPlayer[],
    faceOffs: IFaceOff[]
) => {
    const rosterIds = getRostedIds(roster);
    return [
        ...faceOffs.filter((faceOff) =>
            rosterIds.includes(`${faceOff.winnerId.id}`)
        ),
    ].sort((a: IFaceOff, b: IFaceOff) => a.time - b.time);
};

export const getPlayerFaceOffWinner = (
    playerId: string,
    faceOffs: IFaceOff[]
) => {
    return [
        ...faceOffs.filter((faceOff) => `${faceOff.winnerId.id}` === playerId),
    ].sort((a: IFaceOff, b: IFaceOff) => a.time - b.time);
};

export const getPlayerFaceOffCountWinner = (
    playerId: string,
    faceOffs: IFaceOff[]
) => {
    return getPlayerFaceOffWinner(playerId, faceOffs).length;
};

export const getTeamFaceOffLoser = (
    roster: IPlayer[],
    faceOffs: IFaceOff[]
) => {
    const rosterIds = getRostedIds(roster);
    return [
        ...faceOffs.filter((faceOff) =>
            rosterIds.includes(`${faceOff.loserId.id}`)
        ),
    ].sort((a: IFaceOff, b: IFaceOff) => a.time - b.time);
};

export const getPlayerFaceOffLoser = (
    playerId: string,
    faceOffs: IFaceOff[]
) => {
    return [
        ...faceOffs.filter((faceOff) => `${faceOff.loserId.id}` === playerId),
    ].sort((a: IFaceOff, b: IFaceOff) => a.time - b.time);
};

export const getPlayerFaceOffCountLoser = (
    playerId: string,
    faceOffs: IFaceOff[]
) => {
    return getPlayerFaceOffLoser(playerId, faceOffs).length;
};

export const getTeamFaceOffWithShot = (
    roster: IPlayer[],
    faceOffs: IFaceOff[],
    shots: IShot[],
    positions: IFaceOffLocation[]
) => {
    const teamFaceOffs = getTeamFaceOffWinner(roster, faceOffs).filter(
        (faceOff) => positions.includes(faceOff.location)
    );
    const teamShots = getTeamShots(roster, shots);

    return [
        ...teamFaceOffs.filter((faceOff) =>
            teamShots.some((shot) =>
                isInTime(faceOff.time, faceOff.time + 5, shot.time)
            )
        ),
    ];
};

export const prepareTeamFaceOffPlayers = (
    roster: IPlayer[],
    faceOffs: IFaceOff[]
) => {
    return [
        ...roster
            .map((player) => {
                const wins = getPlayerFaceOffCountWinner(player.id, faceOffs);
                const loses = getPlayerFaceOffCountLoser(player.id, faceOffs);
                const percent = getPercent(wins, wins + loses, 0);
                return { ...player, wins, loses, percent };
            })
            .filter((player) => player.wins !== 0 || player.loses !== 0),
    ];
};

export const prepareFaceOffMapData = (
    homePlayers: IFaceOffPlayer[],
    awayPlayers: IFaceOffPlayer[],
    faceOffs: IFaceOff[]
) => {
    const positions: IFaceOffMapPosition[] = [
        "topLeft",
        "topRight",
        "bottomLeft",
        "bottomRight",
        "center",
    ];
    let res: any = {};
    positions.forEach((pos) => {
        res[pos] = formatFaceOffPosition(
            homePlayers,
            awayPlayers,
            faceOffs,
            pos
        );
    });

    return res;
};

export const formatFaceOffPosition = (
    homePlayers: IFaceOffPlayer[],
    awayPlayers: IFaceOffPlayer[],
    faceOffs: IFaceOff[],
    position: IFaceOffMapPosition
) => {
    const home = getTeamFaceOffWinner(homePlayers, faceOffs).filter((faceOff) =>
        getFaceOffLocationShortcuts(position).includes(faceOff.location)
    ).length;
    const away = getTeamFaceOffWinner(awayPlayers, faceOffs).filter((faceOff) =>
        getFaceOffLocationShortcuts(position).includes(faceOff.location)
    ).length;

    return { home, away };
};

export const getFaceOffLocationShortcuts = (position: IFaceOffMapPosition) => {
    switch (position) {
        case "topLeft":
            return ["HDL"];
        case "topRight":
            return ["ADR"];
        case "bottomLeft":
            return ["HDR"];
        case "bottomRight":
            return ["ADL"];
        default:
            return ["C", "HBL", "HBR", "ABR", "ABL"];
    }
};

export const getFaceOffMapPosition = (location: IFaceOffLocation) => {
    switch (location) {
        case "HDL":
            return "topLeft";
        case "ADR":
            return "topRight";
        case "HDR":
            return "bottomLeft";
        case "ADL":
            return "bottomRight";
        default:
            return "center";
    }
};

export const filterFaceOff = (
    home: string,
    away: string,
    part: string,
    faceOffs: IFaceOff[]
) => {
    let res = faceOffs;
    if (home) {
        res = res.filter(
            (faceOff) =>
                faceOff.winnerId.id === home || faceOff.loserId.id === home
        );
    }
    if (away) {
        res = res.filter(
            (faceOff) =>
                faceOff.winnerId.id === away || faceOff.loserId.id === away
        );
    }
    if (part) {
        res = res.filter((faceOff) =>
            getFaceOffLocationShortcuts(part as IFaceOffMapPosition).includes(
                faceOff.location
            )
        );
    }

    return res;
};

export const formatFaceOffValue = (playerId: string, faceOffs: IFaceOff[]) => {
    const wins = getPlayerFaceOffCountWinner(playerId, faceOffs);
    const losses = getPlayerFaceOffCountLoser(playerId, faceOffs);

    return `${wins}:${losses}`;
};

export const sortByFaceOffValue = (a: IDetailData, b: IDetailData) => {
    const valuesA = a.formattedValue!.split(":");
    const valuesB = b.formattedValue!.split(":");

    if (valuesA[0] === valuesB[0]) {
        return Number(valuesB[1]) - Number(valuesA[1]);
    }
    return Number(valuesB[0]) - Number(valuesA[0]);
};

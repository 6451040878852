import { useEffect, useState } from "react";
import FiltersContainer from "../03-Partials/FiltersContainer";
import FiltersRow from "../04-Micros/FiltersRow";
import GoalkeepersMap from "../03-Partials/GoalkeepersMap";
import StatsBoxSimple from "../03-Partials/StatsBoxSimple";
import { useAppSelector } from "../../Redux/store";
import {
    filterByGameState,
    filterByThirds,
    getGameStateFilters,
    getTeamGoalkeepers,
    getTeamGoalkeepersData,
    getThirdsFilters,
    prepareGoalkeepersStats,
} from "../../Utils";
import { IMatch, IPlayer } from "../../Types";
import Loading from "../04-Micros/Loading";

const Goalkeepers = () => {
    const match = useAppSelector((state) => state.match.value);
    const [filteredMatch, setFilteredMatch] = useState<IMatch>();
    const [gameStateActive, setGameStateActive] = useState<string>("");
    const [thirdsActive, setThirdsActive] = useState<string>("");

    const [activeGoalkeeperHome, setActiveGoalkeeperHome] =
        useState<string>("");
    const [activeGoalkeeperAway, setActiveGoalkeeperAway] =
        useState<string>("");
    const [homeGoalkeepers, setHomeGoalkeepers] = useState<IPlayer[]>([]);
    const [awayGoalkeepers, setAwayGoalkeepers] = useState<IPlayer[]>([]);

    useEffect(() => {
        let tmp = match;

        if (gameStateActive !== "All") {
            tmp = filterByGameState(tmp, gameStateActive);
        }
        if (thirdsActive !== "All") {
            tmp = filterByThirds(tmp, thirdsActive);
        }
        setFilteredMatch(tmp);
    }, [gameStateActive, thirdsActive, match]);

    useEffect(() => {
        if (filteredMatch) {
            const home = getTeamGoalkeepers(
                filteredMatch.homeTeam.roster,
                filteredMatch.shot
            );
            const away = getTeamGoalkeepers(
                filteredMatch.awayTeam.roster,
                filteredMatch.shot
            );

            setHomeGoalkeepers(home);
            setAwayGoalkeepers(away);
            setActiveGoalkeeperHome(home[0].id);
            setActiveGoalkeeperAway(away[0].id);
        }
    }, [filteredMatch]);

    return (
        <>
            <FiltersContainer>
                <FiltersRow
                    filters={getGameStateFilters("SH")}
                    active={gameStateActive}
                    setActive={setGameStateActive}
                />
                <FiltersRow
                    filters={getThirdsFilters(match.length, match.game)}
                    active={thirdsActive}
                    setActive={setThirdsActive}
                />
            </FiltersContainer>
            <main className="Goalkeepers">
                {!filteredMatch ? (
                    <Loading />
                ) : (
                    <>
                        <GoalkeepersMap
                            players={homeGoalkeepers}
                            data={getTeamGoalkeepersData(
                                homeGoalkeepers,
                                filteredMatch.shot
                            )}
                            activeGoalkeeper={activeGoalkeeperHome}
                            setActiveGoalkeeper={setActiveGoalkeeperHome}
                        />
                        <div className="Goalkeepers-stats">
                            {prepareGoalkeepersStats(
                                activeGoalkeeperHome,
                                activeGoalkeeperAway,
                                filteredMatch.shot
                            ).map((stat) => (
                                <StatsBoxSimple key={stat.title} {...stat} />
                            ))}
                        </div>
                        <GoalkeepersMap
                            players={awayGoalkeepers}
                            data={getTeamGoalkeepersData(
                                awayGoalkeepers,
                                filteredMatch.shot
                            )}
                            activeGoalkeeper={activeGoalkeeperAway}
                            setActiveGoalkeeper={setActiveGoalkeeperAway}
                        />
                    </>
                )}
            </main>
        </>
    );
};

export default Goalkeepers;

import { FC } from "react";

interface IProps {
    alone?: boolean;
    double?: boolean;
    children: JSX.Element | JSX.Element[];
}

const StatsSmallContainer: FC<IProps> = ({ alone, double, children }) => {
    return (
        <div
            className={`StatsSmallContainer${alone ? " alone" : ""}${
                double ? " double" : ""
            }`}
        >
            {children}
        </div>
    );
};

export default StatsSmallContainer;

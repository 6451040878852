import { IActivityResponse } from "../Types";
import { axiosInstance } from "./axiosInstance";

export const logScreen = async (
    token: string,
    tab: string,
    subTab?: string
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const body = {
        tab,
        subTab,
    };

    const response: IActivityResponse = await axiosInstance.post(
        `/screen-log`,
        body,
        {
            headers,
        }
    );
    return response.data;
};

export const getScreenLog = async (token: string) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const response: IActivityResponse = await axiosInstance.get(`/screen-log`, {
        headers,
    });
    return response.data;
};

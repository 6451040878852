import { compareAsc, format, subMinutes } from "date-fns";
import { cs } from "date-fns/locale";

export const formatDate = (date: Date, formatString: string) =>
    format(date, formatString, { locale: cs });

export const formatSeconds = (seconds: number) => {
    return `${addLeadingZero(Math.floor(seconds / 60))}:${addLeadingZero(
        Math.round(seconds % 60)
    )}`;
};

export const formatSecondsToDecimalMinutes = (seconds: number) => {
    return (seconds / 60).toFixed(2).replace(".", ",");
};

export const addLeadingZero = (value: number) =>
    value < 10 ? `0${value}` : value;

export const isTimeInMinutes = (date: Date, minutes: number) => {
    const min = subMinutes(date, minutes);

    return compareAsc(new Date(), min) === 1 ? true : false;
};

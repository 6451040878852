import { DependencyList, useCallback, useEffect, useRef } from "react";

export const useClickOutside = <T extends Node>(
    callback: () => void,
    deps: DependencyList
) => {
    const ref = useRef<T | null>(null);

    const memoizedCallback = useCallback(() => {
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                memoizedCallback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [memoizedCallback]);

    return ref;
};

export const useClickOutsideTwoRefs = <T extends Node, P extends Node>(
    callback: () => void,
    deps: DependencyList
) => {
    const ref = useRef<T | null>(null);
    const refDontClose = useRef<P | null>(null);

    const memoizedCallback = useCallback(() => {
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && refDontClose.current) {
                const letItOpen =
                    ref.current.contains(event.target as Node) ||
                    refDontClose.current.contains(event.target as Node);

                if (!letItOpen) {
                    memoizedCallback();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [memoizedCallback]);

    return {
        ref,
        refDontClose,
    };
};

import { FC } from "react";
import { IGateZone } from "../../Types";

export interface IGoalkeeperMapStats {
	zone: IGateZone;
	goals: number;
	outOf: number;
	left?: number | string;
	top?: number | string;
	right?: number | string;
	bottom?: number | string;
}

const GoalkeepersMapStats: FC<IGoalkeeperMapStats> = ({
	zone,
	goals,
	outOf,
	left,
	top,
	right,
	bottom,
}) => {
	return (
		<div
			className={`GoalkeepersMapStats${goals > 0 ? " goal" : ""}`}
			style={{ top, left, bottom, right }}
		>
			{zone === "ce" ? "" : `${goals}/`}
			{outOf}
		</div>
	);
};

export default GoalkeepersMapStats;

import { FC } from "react";
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from "../../Icons";

interface IProps {
	disabled: boolean;
	type: "prev" | "next";
	onClick: () => void;
}

const PaginationButton: FC<IProps> = ({ disabled, type, onClick }) => {
	return (
		<div className={`PaginationButton ${type}${disabled ? " disabled" : ""}`} onClick={onClick}>
			{type === "prev" ? <ArrowCircleLeftIcon /> : <ArrowCircleRightIcon />}
			<p>{type === "prev" ? "Předchozí" : "Následující"}</p>
		</div>
	);
};

export default PaginationButton;

import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { fillModalData, toggleModal } from "../../Redux/statsDetail";
import { IDetailData, IDetailModal } from "../../Types/statsDetail";
import { GREEN_HIGHLIGHT, RED_HIGHLIGHT, formatSeconds } from "../../Utils";

export interface IStatsBox {
    title: string;
    homeStats: number;
    awayStats: number;
    time?: boolean;
    big?: boolean;
    homePlayers?: IDetailData[];
    awayPlayers?: IDetailData[];
    fixed?: number;
    single?: "home" | "away";
}

const StatsBox: FC<IStatsBox> = ({
    title,
    homeStats,
    awayStats,
    time,
    big,
    homePlayers,
    awayPlayers,
    fixed,
    single,
}) => {
    const [lineWidthHome, setLineWidthHome] = useState<number>(0);
    const [lineWidthGuest, setLineWidthGuest] = useState<number>(0);
    const [lineColorHome, setLineColorHome] = useState<number>(0);
    const [lineColorGuest, setLineColorGuest] = useState<number>(0);
    const dispatch = useAppDispatch();

    const identity = useAppSelector((state) => state.login.identity);
    const awayTeam = useAppSelector((state) => state.match.value.awayTeam.id);

    const handleClick = () => {
        const statsInfo: IDetailModal = {
            title: title,
            homeStats: fixed ? Number(homeStats.toFixed(fixed)) : homeStats,
            awayStats: fixed ? Number(awayStats.toFixed(fixed)) : awayStats,
            homePlayers: homePlayers ? homePlayers : [],
            awayPlayers: awayPlayers ? awayPlayers : [],
            fixed,
        };

        dispatch(fillModalData(statsInfo));
        dispatch(toggleModal());
    };
    const setColor = (width: number, setFunc: (value: number) => void) => {
        if (width > 80) setFunc(4);
        else if (width > 60) setFunc(3);
        else if (width > 40) setFunc(2);
        else if (width > 20) setFunc(1);
        else setFunc(0);
    };

    useEffect(() => {
        setLineWidthHome((100 * homeStats) / (homeStats + awayStats));
        setLineWidthGuest((100 * awayStats) / (homeStats + awayStats));
        setColor(lineWidthHome, setLineColorHome);
        setColor(lineWidthGuest, setLineColorGuest);
    }, [homeStats, awayStats, lineWidthHome, lineWidthGuest]);

    if (single) {
        const value = single === "home" ? homeStats : awayStats;

        const getLineColor = () => {
            if (single === "home") {
                return identity.teamId === awayTeam
                    ? RED_HIGHLIGHT
                    : GREEN_HIGHLIGHT;
            } else {
                return identity.teamId === awayTeam
                    ? GREEN_HIGHLIGHT
                    : RED_HIGHLIGHT;
            }
        };

        return (
            <div
                className={`StatsBox${big ? " big" : ""}${
                    homePlayers && awayPlayers ? "" : " disabled"
                }`}
                onClick={homePlayers && awayPlayers && handleClick}
            >
                <p className="StatsBox-title">{title}</p>
                {time ? (
                    <h1 className="StatsBox-stats">{formatSeconds(value)}</h1>
                ) : (
                    <h1 className="StatsBox-stats">{`${
                        fixed ? value.toFixed(fixed) : value
                    }`}</h1>
                )}
                <div className={`StatsBox-line`}>
                    <div
                        className={`StatsBox-highlightLine home`}
                        style={{
                            width: `100%`,
                            backgroundColor: getLineColor()[4],
                        }}
                    ></div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`StatsBox${big ? " big" : ""}${
                homePlayers && awayPlayers ? "" : " disabled"
            }`}
            onClick={homePlayers && awayPlayers && handleClick}
        >
            <p className="StatsBox-title">{title}</p>
            {time ? (
                <h1 className="StatsBox-stats">
                    {formatSeconds(homeStats)} - {formatSeconds(awayStats)}
                </h1>
            ) : (
                <h1 className="StatsBox-stats">{`${
                    fixed ? homeStats.toFixed(fixed) : homeStats
                } - ${fixed ? awayStats.toFixed(fixed) : awayStats}`}</h1>
            )}
            <div className={`StatsBox-line`}>
                <div
                    className={`StatsBox-highlightLine home`}
                    style={{
                        width: `${lineWidthHome}%`,
                        backgroundColor: (identity.teamId === awayTeam
                            ? RED_HIGHLIGHT
                            : GREEN_HIGHLIGHT)[lineColorHome],
                    }}
                ></div>
                <div
                    className={`StatsBox-highlightLine guest`}
                    style={{
                        width: `${lineWidthGuest}%`,
                        backgroundColor: (identity.teamId === awayTeam
                            ? GREEN_HIGHLIGHT
                            : RED_HIGHLIGHT)[lineColorGuest],
                    }}
                ></div>
            </div>
        </div>
    );
};

export default StatsBox;

import { FC, useState } from "react";
import Button from "@mui/material/Button";
import { ClockIcon, LogoutIcon, QuestionIcon } from "../../Icons";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import Loading from "../04-Micros/Loading";
import { useGetMatchDetail } from "../../Hooks";
import { setLogout } from "../../Redux/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";

interface IProps {
    dataTime: string;
    dataCheckTime: string;
    dataStatus: boolean;
}

const TopHeader: FC<IProps> = ({ dataTime }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const matchId = useAppSelector((state) => state.match.value.id);

    const { isLoading, handleLoadData } = useGetMatchDetail(
        matchId,
        true,
        false
    );

    const [hint, setHint] = useState<boolean>(false);

    const handleLogOut = () => {
        dispatch(setLogout());
        sessionStorage.clear();
        navigate("/login");
        toast.success("Odhlášení proběhlo úspěšně.", {
            toastId: "logoutSucces",
        });
    };

    const handleHint = () => setHint((prevState) => !prevState);

    return (
        <>
            {isLoading && <Loading />}
            <div className="TopHeader">
                <div className="TopHeader-stats">
                    <div className="TopHeader-stats--basicTab">
                        <p>
                            Data k času:{" "}
                            <span className="highlight">{dataTime}</span>
                        </p>
                    </div>
                </div>
                <div className="TopHeader-buttons">
                    <Button
                        variant="text"
                        startIcon={<QuestionIcon />}
                        onClick={handleHint}
                    >
                        Nápověda
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<ClockIcon />}
                        onClick={handleLoadData}
                    >
                        Obnovit data
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<LogoutIcon />}
                        onClick={handleLogOut}
                    >
                        Odhlásit se
                    </Button>
                </div>
            </div>

            <Sidebar open={hint} onClose={handleHint} />
        </>
    );
};

export default TopHeader;

import { FC, useState } from "react";
import ShotsPopup from "./ShotsPopup";
import { useClickOutside } from "../../Hooks";

interface IProps {
    id: string;
    jersey: string;
    name: string;
    surname: string;
    time: number;
    xG: number;
    top: string | number;
    left: string | number;
    type?: "goal" | "catched" | "blocked";
}

const DetailShotDot: FC<IProps> = ({
    id,
    jersey,
    name,
    surname,
    time,
    xG,
    type,
    top,
    left,
}) => {
    const [popup, setPopup] = useState<boolean>(false);

    const ref = useClickOutside<HTMLDivElement>(() => {
        setPopup(false);
    }, []);

    const handleClick = () => {
        setPopup(true);
    };

    return (
        <div
            ref={ref}
            className="DetailShotDot"
            style={{ top: top, left: left }}
            onClick={handleClick}
        >
            {popup && (
                <ShotsPopup
                    id={id}
                    jersey={jersey}
                    name={name}
                    surname={surname}
                    time={time}
                    xG={xG}
                />
            )}
            <div className={`DetailShotDot-dot${type ? " " + type : ""}`}>
                {jersey}
            </div>
        </div>
    );
};

export default DetailShotDot;

import { FC, useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { useAppSelector } from "../../Redux/store";

interface IProps {
    valueHome?: number;
    valueGuest?: number;
    toi?: number;
    outOf?: number;
}

const PieChartWrapper: FC<IProps> = ({ valueHome, valueGuest, toi, outOf }) => {
    const identity = useAppSelector((state) => state.login.identity);
    const awayId = useAppSelector((state) => state.match.value.awayTeam.id);

    const colors = ["#099268", "#e02021"];
    const [size, setSize] = useState<number>(0);

    useEffect(() => {
        setSize(getSize());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toi, outOf]);

    const getSize = () => {
        if (toi && outOf) {
            const size = (toi / outOf) * 50;
            if (size < 14) return 14;
            else return size;
        } else return 14;
    };

    const getColor = (index: number) => {
        if (index === 0) {
            return identity.teamId === awayId ? colors[1] : colors[0];
        } else {
            return identity.teamId === awayId ? colors[0] : colors[1];
        }
    };

    return (
        <>
            {valueHome || valueGuest ? (
                <div className="PieChartWrapper">
                    <PieChart width={size} height={size}>
                        <Pie
                            data={[
                                { name: "home", value: valueHome },
                                { name: "guest", value: valueGuest },
                            ]}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            strokeWidth={!valueHome || !valueGuest ? 0 : 0.5}
                            outerRadius={40}
                            isAnimationActive={false}
                        >
                            {[valueHome, valueGuest].map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={getColor(index)}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
            ) : (
                <div className="PieChartWrapper">
                    <div className="PieChartWrapper-empty"></div>
                </div>
            )}
        </>
    );
};

export default PieChartWrapper;

import { IMatchDetails, IMatchInfo } from "../Components/03-Partials/MatchInfo";
import {
    IGameState,
    IListMatch,
    IMatch,
    IPlayer,
    IState,
    ITeam,
} from "../Types";
import { formatDate, formatSeconds, isTimeInMinutes } from ".";
import { IPlayerPickerItemProps } from "../Components/04-Micros/PlayerPickerItem";

export const formatMatchInfo = (match: IListMatch): IMatchInfo => {
    const date = new Date(match.date);
    return {
        matchId: match.id,
        homeTeam: match.homeTeam.shortcut,
        awayTeam: match.awayTeam.shortcut,
        leagueName: match.leagueName,
        matchDate: formatDate(date, "HH:mm"),
        disabled: isTimeInMinutes(date, 5) ? false : true,
    };
};

export const formatMatchDetails = (match: IMatch): IMatchDetails => {
    return {
        homeScore: match.homeTeam.score,
        awayScore: match.awayTeam.score,
        dataTime: formatSeconds(match.length),
        dataChecked: formatDate(new Date(match.lastModification), "HH:mm:ss"),
    };
};

export const getRostedIds = (roster: IPlayer[]) => [
    ...roster.map((player) => player.id),
];

export const getPlayerById = (playerId: string, roster: IPlayer[]) => {
    const res = roster.find((player) => player.id === playerId);
    return res ? res : null;
};

export const getPlayersRelation = (
    playerId: string,
    withId: string,
    match: IMatch
) => {
    if (getPlayerTeam(playerId, match) === getPlayerTeam(withId, match))
        return "teammate";
    else return "opponent";
};

export const getPlayerTeam = (playerId: string, match: IMatch) => {
    if (getPlayerById(playerId, match.homeTeam.roster)) return "home";
    else return "away";
};

export const getGameStates = (state: "PP" | "SH" | IState) => {
    switch (state) {
        case "PP":
            return ["5:4", "5:3", "4:3", "6:4", "6:3"];
        case "SH":
            return ["4:5", "3:5", "3:4", "4:6", "3:6"];
        default:
            return [state];
    }
};

export const isInTime = (start: number, end: number, time: number) => {
    return start <= time && end >= time;
};

export const gameStateIncludes = (gameState: IGameState[], time: number) => {
    return gameState.some((state) => isInTime(state.start, state.end, time));
};

export const getThirdsStart = (value: string) => {
    if (!value.includes("overtime")) {
        const start = (Number(value) - 1) * 1200;
        return start === 0 ? 0 : start + 1;
    }
    if (value === "overtime") return 3601;

    const overtimeIndex = Number(value.split("overtime")[1]);
    const start = 3600 + (overtimeIndex - 1) * 1200;

    return start + 1;
};

export const getThirdsInterval = (value: string) => {
    const start = getThirdsStart(value);
    const end = start + (start === 0 ? 1200 : 1199);

    return { start, end };
};

export const getPlayersByPosition = (
    roster: IPlayer[],
    position: string
): IPlayer[] => [...roster].filter((player) => player.position === position);

export const sortPlayersByLine = (players: IPlayer[]): IPlayer[] =>
    [...players].sort((a, b) => a.lineUp.line - b.lineUp.line);

export const comparePlayersByLineup = (a: IPlayer, b: IPlayer) => {
    const lineupPosition = { LD: 0, RD: 1, LW: 2, CE: 3, RW: 4 };

    if (a.position === "backward" && b.position === "forward") {
        return -1;
    } else if (a.position === "forward" && b.position === "backward") {
        return 1;
    } else {
        if (a.lineUp.line === b.lineUp.line) {
            return (
                lineupPosition[a.lineUp.position] -
                lineupPosition[b.lineUp.position]
            );
        }
        return a.lineUp.line - b.lineUp.line;
    }
};

export const getSortedPlayerItemsByNumber = (
    players: IPlayerPickerItemProps[],
    arraySize: number
): IPlayerPickerItemProps[][] => {
    let arr: IPlayerPickerItemProps[][] = [];
    let pomArr: IPlayerPickerItemProps[] = [];
    let playersArr = [...players];
    playersArr.forEach((element, index) => {
        pomArr.push(element);
        if (index !== 0 && (index + 1) % arraySize === 0) {
            arr.push(pomArr);
            pomArr = [];
        }
    });
    if (players.length % arraySize !== 0) arr.push(pomArr);
    return arr;
};

export const transformPlayersToPlayerItems = (
    pickedPlayersPending: IPlayer[],
    roster: IPlayer[],
    position: string
): IPlayerPickerItemProps[] =>
    getPlayersByPosition(roster, position).map((player) => {
        return {
            player: player,
            active: pickedPlayersPending.includes(player),
            onItemClick: () => undefined,
        };
    });

export const getTeamsTimeInPowerPlay = (team: ITeam, gameTime: number) => {
    return team.gameState.reduce((accumulator, object) => {
        if (isStatePowerPlay(object.state)) {
            if (object.start > gameTime) {
                return accumulator;
            } else if (object.end > gameTime) {
                return accumulator + (gameTime - object.start);
            } else {
                return accumulator + (object.end - object.start);
            }
        } else {
            return accumulator;
        }
    }, 0);
};

export const isStatePowerPlay = (state: IState) => {
    return getGameStates("PP").includes(state);
};

export const filterGameStates = (state: IGameState) =>
    state.state !== "5:5" && (state.start < 3600 || state.state !== "3:3");

export const getGameStateByTime = (
    states: IGameState[],
    time: number
): IState => {
    const state = states.find((state) =>
        isInTime(state.start, state.end, time)
    );

    return state ? state.state : "5:5";
};

export const getTeamsGameStates = (playerId: string, match: IMatch) => {
    return match[
        getPlayerTeam(playerId, match) === "home" ? "homeTeam" : "awayTeam"
    ].gameState;
};

import { useEffect, useState } from "react";
import FiltersContainer from "../03-Partials/FiltersContainer";
import StatsBigContainer from "../02-Macros/StatsBigContainer";
import StatsSmallContainer from "../02-Macros/StatsSmallContainer";
import StatsDetailModal from "../03-Partials/StatsDetailModal";
import FiltersRow from "../04-Micros/FiltersRow";
import { useAppSelector } from "../../Redux/store";
import {
    filterByGameState,
    filterByThirds,
    getGameStateFilters,
    getPowerPlayByThirds,
    getTeamsTimeInPowerPlay,
    getThirdsFilters,
    prepareOverviewBigStats,
    prepareOverviewSmallStats,
} from "../../Utils";
import StatsBox from "../03-Partials/StatsBox";
import { IMatch, ITeamPowerPlayTime } from "../../Types";
import Loading from "../04-Micros/Loading";
import VideoModal from "../03-Partials/VideoModal";

const Overview = () => {
    const match = useAppSelector((state) => state.match.value);

    const [filteredMatch, setFilteredMatch] = useState<IMatch>();
    const [powerPlay, setPowerPlay] = useState<ITeamPowerPlayTime>({
        home: 0,
        away: 0,
    });
    const [gameStateActive, setGameStateActive] = useState<string>("");
    const [thirdsActive, setThirdsActive] = useState<string>("");

    useEffect(() => {
        let tmpMatch = match;
        let tmpPowerPlay = {
            home: getTeamsTimeInPowerPlay(match.homeTeam, match.length),
            away: getTeamsTimeInPowerPlay(match.awayTeam, match.length),
        };

        if (gameStateActive !== "All") {
            tmpMatch = filterByGameState(tmpMatch, gameStateActive);
        }
        if (thirdsActive !== "All") {
            tmpMatch = filterByThirds(tmpMatch, thirdsActive);
            tmpPowerPlay = getPowerPlayByThirds(
                match.homeTeam.gameState,
                match.awayTeam.gameState,
                thirdsActive
            );
        }

        setFilteredMatch(tmpMatch);
        setPowerPlay(tmpPowerPlay);
    }, [gameStateActive, thirdsActive, match]);

    useEffect(() => {
        console.log(filteredMatch);
    }, [filteredMatch]);

    return (
        <>
            <FiltersContainer>
                <FiltersRow
                    filters={getGameStateFilters()}
                    active={gameStateActive}
                    setActive={setGameStateActive}
                />
                <FiltersRow
                    filters={getThirdsFilters(match.length, match.game)}
                    active={thirdsActive}
                    setActive={setThirdsActive}
                />
            </FiltersContainer>
            <main className="Overview">
                {!filteredMatch ? (
                    <Loading />
                ) : (
                    <>
                        <StatsBigContainer>
                            {prepareOverviewBigStats(filteredMatch).map(
                                (stat, index) => (
                                    <StatsBox key={index} {...stat} big />
                                )
                            )}
                        </StatsBigContainer>
                        <StatsSmallContainer double>
                            <>
                                {gameStateActive === "PP" && (
                                    <StatsBox
                                        title="Čas odehraný v PP"
                                        homeStats={powerPlay.home}
                                        awayStats={powerPlay.away}
                                        time
                                    />
                                )}
                                {prepareOverviewSmallStats(filteredMatch).map(
                                    (stat, index) => (
                                        <StatsBox key={index} {...stat} />
                                    )
                                )}
                            </>
                        </StatsSmallContainer>
                    </>
                )}
            </main>
            <StatsDetailModal />
            <VideoModal />
        </>
    );
};

export default Overview;

import MatchInfoHeader from "../03-Partials/MatchInfoHeader";
import TopHeader from "../03-Partials/TopHeader";
import { useAppSelector } from "../../Redux/store";
import { formatDate, formatSeconds } from "../../Utils";

const Header = () => {
	const match = useAppSelector((state) => state.match.value);

	return (
		<div className="Header">
			{match.id && (
				<>
					<TopHeader
						dataTime={formatSeconds(match.length)}
						dataCheckTime={formatDate(new Date(match.lastModification), "HH:mm:ss")}
						dataStatus={true}
					/>
					<MatchInfoHeader
						homeTeam={match.homeTeam.shortcut}
						homeScore={match.homeTeam.score}
						awayTeam={match.awayTeam.shortcut}
						awayScore={match.awayTeam.score}
					/>
				</>
			)}
		</div>
	);
};

export default Header;

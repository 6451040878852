import { FC } from "react";
import { useAppSelector } from "../../Redux/store";

interface IProps {
	title: string;
	homeStats: number;
	awayStats: number;
}

const StatsDetailHeader: FC<IProps> = ({ title, homeStats, awayStats }) => {
	const homeTeam = useAppSelector((state) => state.match.value.homeTeam.shortcut);
	const awayTeam = useAppSelector((state) => state.match.value.awayTeam.shortcut);

	return (
		<header className="StatsDetailHeader">
			<div className="StatsDetailHeader-team">
				<img src={require(`../../Assets/logos/${homeTeam}.png`)} alt="team logo" />
				<p>{homeTeam}</p>
			</div>
			<div className="StatsDetailHeader-center">
				<p className="StatsDetailHeader-center--title">{title}</p>
				<p className="StatsDetailHeader-center--stats">
					{homeStats} - {awayStats}
				</p>
			</div>
			<div className="StatsDetailHeader-team">
				<p>{awayTeam}</p>
				<img src={require(`../../Assets/logos/${awayTeam}.png`)} alt="team logo" />
			</div>
		</header>
	);
};

export default StatsDetailHeader;

//Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVideo } from "../Types";
//Types

interface IModal {
    value: {
        state: boolean;
        active: number;
        videos: IVideo[];
    };
}

const videoInitialState: IModal = {
    value: {
        state: false,
        active: 0,
        videos: [],
    },
};

export const videoSlice = createSlice({
    name: "video",
    initialState: videoInitialState,
    reducers: {
        toggleVideo: (state) => {
            if (state.value.state) {
                state.value.active = 0;
            }
            state.value.state = !state.value.state;
        },
        fillVideoData: (state, action: PayloadAction<IVideo[]>) => {
            state.value.videos = action.payload;
        },
        setActiveVideo: (state, action: PayloadAction<number>) => {
            state.value.active = action.payload;
        },
    },
});

export const { toggleVideo, fillVideoData, setActiveVideo } =
    videoSlice.actions;
export default videoSlice.reducer;

import { FC, useEffect, useState } from "react";
import Buttons from "../04-Micros/Buttons";
import GoalkeepersMapStats, {
    IGoalkeeperMapStats,
} from "../04-Micros/GoalkeepersMapStats";
import { IPlayer } from "../../Types";

interface IProps {
    players: IPlayer[];
    data: any;
    activeGoalkeeper: string;
    setActiveGoalkeeper: (value: string) => void;
}

const GoalkeepersMap: FC<IProps> = ({
    players,
    data,
    activeGoalkeeper,
    setActiveGoalkeeper,
}) => {
    const [activeData, setActiveData] = useState<IGoalkeeperMapStats[]>([]);

    const disableGoalkeeper = (id: string) => {
        let state = true;
        data[id].forEach((zone: IGoalkeeperMapStats) => {
            if (zone.outOf > 0) {
                state = false;
            }
        });
        return state;
    };

    useEffect(() => {
        if (activeGoalkeeper) {
            setActiveData(data[activeGoalkeeper]);
        }
    }, [activeGoalkeeper, data]);

    return (
        <div className="GoalkeepersMap">
            {players.map((player) => {
                if (player.id === activeGoalkeeper) {
                    return (
                        <div
                            key={player.jersey}
                            className="GoalkeepersMap-activePlayer"
                        >
                            <div className="GoalkeepersMap-activePlayer--number">
                                {player.jersey}
                            </div>
                            <p className="GoalkeepersMap-activePlayer--name">
                                <span>{player.name}</span> {player.surname}
                            </p>
                        </div>
                    );
                }
                return "";
            })}
            <div className="GoalkeepersMap-map">
                <img src={require("../../Assets/branka.png")} alt="" />
                {activeData.map((gateZone, index) => (
                    <GoalkeepersMapStats key={index} {...gateZone} />
                ))}
            </div>
            <div className="GoalkeepersMap-players">
                {players.map((player, index) => (
                    <Buttons
                        key={index}
                        type="contained"
                        title={`${player.jersey} ${player.name} ${player.surname}`}
                        color={
                            activeGoalkeeper === player.id ? "black" : "grey"
                        }
                        onClick={() => setActiveGoalkeeper(player.id)}
                        disabled={disableGoalkeeper(player.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default GoalkeepersMap;

//Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDetailModal, IStatsDetail } from "../Types/statsDetail";
//Types

interface IModal {
	value: IStatsDetail;
}

const statsDetailInitialState: IModal = {
	value: {
		state: false,
		title: "",
		homeStats: 0,
		awayStats: 0,
		homePlayers: [],
		awayPlayers: [],
	},
};

export const statsDetailSlice = createSlice({
	name: "statsDetail",
	initialState: statsDetailInitialState,
	reducers: {
		toggleModal: (state) => {
			state.value.state = !state.value.state;
		},
		fillModalData: (state, action: PayloadAction<IDetailModal>) => {
			state.value = { state: state.value.state, ...action.payload };
		},
	},
});

export const { toggleModal, fillModalData } = statsDetailSlice.actions;
export default statsDetailSlice.reducer;

import Header from "./Header";
import MainNavigation from "./MainNavigation";
import Overview from "../01-Pages/Overview";
import Shots from "../01-Pages/Shots";
import Formations from "../01-Pages/Formations";
import Goalkeepers from "../01-Pages/Goalkeepers";
import FaceOff from "../01-Pages/FaceOff";
import IceTime from "../01-Pages/IceTime";
import { TabPanel } from "@mui/lab";
import { useGetMatchDetail } from "../../Hooks";
import { getSession } from "../../Utils";
import Loading from "../04-Micros/Loading";
import Head2Head from "../01-Pages/HeadToHead";
import Momentum from "../01-Pages/Momentum";

const Layout = () => {
    const matchId = getSession("currentMatch");
    const { isLoading } = useGetMatchDetail(matchId!, true, true);

    return (
        <div className="Layout">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Header />
                    <div className="Layout-body">
                        <TabPanel value="overview">
                            <Overview />
                        </TabPanel>
                        <TabPanel value="shots">
                            <Shots />
                        </TabPanel>
                        <TabPanel value="formations">
                            <Formations />
                        </TabPanel>
                        <TabPanel value="goalkeepers">
                            <Goalkeepers />
                        </TabPanel>
                        <TabPanel value="faceOff">
                            <FaceOff />
                        </TabPanel>
                        <TabPanel value="iceTime">
                            <IceTime />
                        </TabPanel>
                        <TabPanel value="h2h">
                            <Head2Head />
                        </TabPanel>
                        <TabPanel value="momentum">
                            <Momentum />
                        </TabPanel>
                    </div>
                    <MainNavigation />
                </>
            )}
        </div>
    );
};

export default Layout;

import { useEffect, useState } from "react";
import { IVideo } from "../Types";
import { useAppSelector } from "../Redux/store";
import { getVideoPlayer, getVideoTime } from "../Api/video";
import { DEFAULT_VIDEO_CUT_BEFORE, DEFAULT_VIDEO_SEGMENT } from "../Utils";

export const useVideo = (video: IVideo) => {
    const token = useAppSelector((state) => state.login.token.access_token);
    const matchId = useAppSelector((state) => state.match.value.id);

    const [key, setKey] = useState<number>(0);
    const [html, setHtml] = useState<string>("");

    useEffect(() => {
        if (token) {
            setKey((prevState) => prevState + 1);

            getVideoTime(
                token,
                matchId,
                video.faceOffTime ? video.faceOffTime : video.time
            )
                .then((res) => {
                    const time = res.data.videoTime;
                    getVideoPlayer({
                        token,
                        matchId,
                        time: 0,
                        videoType: "segment",
                        videoStartTime: time - DEFAULT_VIDEO_CUT_BEFORE,
                        videoEndTime: time + DEFAULT_VIDEO_SEGMENT,
                    })
                        .then((res) => {
                            console.log(res.data);
                            setHtml(res.data);
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, video]);

    return {
        key,
        html,
    };
};

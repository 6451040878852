import { IInterval, IMomentum } from "../Types";

export const getMomentumThirds = (dataLen: number) => {
    return dataLen / 40;
};

export const getMomentumTimeline = (dataLen: number) => {
    let res: string[] = [];
    const thirds = getMomentumThirds(dataLen);
    let minutes = 0;

    for (let i = 0; i <= thirds; i += 0.25) {
        res.push(`${minutes < 10 ? "0" + minutes : minutes}:00`);
        minutes += 5;
    }

    return res;
};

export const getGoalPosition = (
    time: number,
    width: number,
    thirds: number
) => {
    const percent = width / (thirds * 1200);

    return {
        left: `${time * percent}px`,
    };
};

export const getStatePosition = (
    interval: IInterval,
    width: number,
    thirds: number
) => {
    const percent = width / (thirds * 1200);

    return {
        left: `${interval.start * percent}px`,
        width: `${(interval.end - interval.start) * percent}px`,
    };
};

export const getMaxMomentum = (data: IMomentum[]) => {
    const max = data.reduce((prev, current) =>
        Math.abs(prev.momentum) > Math.abs(current.momentum) ? prev : current
    );
    return Number((Math.ceil(Math.abs(max.momentum) * 2) / 2).toFixed(1));
};

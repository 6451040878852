import Buttons from "../04-Micros/Buttons";
import { Dialog } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { toggleModal } from "../../Redux/statsDetail";
import StatsDetailHeader from "../04-Micros/StatsDetailHeader";
import StatsDetailRow from "../04-Micros/StatsDetailRow";
import { usePagination } from "../../Hooks";
import PaginationButton from "../04-Micros/PaginationButton";

const StatsDetailModal = () => {
    const modal = useAppSelector((state) => state.statsDetail.value);
    const dispatch = useAppDispatch();
    const {
        pagination,
        maxPagination,
        getMinIndex,
        getMaxIndex,
        moveToPrevious,
        moveToNext,
    } = usePagination(5, modal.homePlayers.length);

    const handleClose = () => {
        dispatch(toggleModal());
    };
    return (
        <Dialog
            className="StatsDetailModal"
            open={modal.state}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth
        >
            <StatsDetailHeader
                title={modal.title}
                homeStats={modal.homeStats}
                awayStats={modal.awayStats}
            />
            <div className="StatsDetailModal-data">
                <div className="StatsDetailModal-data--section">
                    {modal.homePlayers
                        .slice(getMinIndex(), getMaxIndex())
                        .map((player, index) => {
                            const realIndex = index + 1 * pagination;
                            return (
                                <StatsDetailRow
                                    key={index}
                                    {...player}
                                    fixed={modal.fixed}
                                    index={realIndex < 4 ? realIndex : 4}
                                />
                            );
                        })}
                </div>
                <div className="StatsDetailModal-data--section">
                    {modal.awayPlayers
                        .slice(getMinIndex(), getMaxIndex())
                        .map((player, index) => {
                            const realIndex = index + 1 * pagination;
                            return (
                                <StatsDetailRow
                                    key={index}
                                    {...player}
                                    fixed={modal.fixed}
                                    index={realIndex < 4 ? realIndex : 4}
                                    switchPlace
                                />
                            );
                        })}
                </div>
            </div>
            <footer className="StatsDetailModal-footer">
                <PaginationButton
                    type="prev"
                    disabled={pagination === 1}
                    onClick={moveToPrevious}
                />
                <Buttons
                    title="Zavřít"
                    type="outlined"
                    color="black"
                    maxWidth={128}
                    onClick={handleClose}
                />
                <PaginationButton
                    type="next"
                    disabled={pagination >= maxPagination}
                    onClick={moveToNext}
                />
            </footer>
        </Dialog>
    );
};

export default StatsDetailModal;

import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import navigationTabReducer from "./navigation";
import statsDetailReducer from "./statsDetail";
import loginReducer from "./login";
import pickedPlayersReducer from "./pickedPlayers";
import matchReducer from "./match";
import videoReducer from "./video";

const store = configureStore({
    reducer: {
        navigationTab: navigationTabReducer,
        statsDetail: statsDetailReducer,
        login: loginReducer,
        pickedPlayers: pickedPlayersReducer,
        match: matchReducer,
        video: videoReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

//Redux
import { createSlice } from "@reduxjs/toolkit";
import { IPlayer } from "../Types";

interface IPickedPlayers {
    value: {
        home: IPlayer[];
        homePending: IPlayer[];
        away: IPlayer[];
        awayPending: IPlayer[];
    };
}

const pickedPlayersInitialState: IPickedPlayers = {
    value: {
        home: [],
        homePending: [],
        away: [],
        awayPending: [],
    },
};

export const pickedPlayersSlice = createSlice({
    name: "pickedPlayers",
    initialState: pickedPlayersInitialState,
    reducers: {
        setHome: (state, payload) => {
            state.value.home = payload.payload;
        },
        setAway: (state, payload) => {
            state.value.away = payload.payload;
        },
        addHomePending: (state, payload) => {
            state.value.homePending.push(payload.payload);
        },
        addAwayPending: (state, payload) => {
            state.value.awayPending.push(payload.payload);
        },
        removeHomePending: (state, payload) => {
            state.value.homePending = state.value.homePending.filter(
                (player) => player.id !== payload.payload.id
            );
        },
        removeAwayPending: (state, payload) => {
            state.value.awayPending = state.value.awayPending.filter(
                (player) => player.id !== payload.payload.id
            );
        },
        clearPickedPlayers: (state) => {
            state.value.home = [];
            state.value.away = [];
        },
        clearPendingPickedPlayers: (state) => {
            state.value.homePending = [];
            state.value.awayPending = [];
        },
    },
});

export const {
    setAway,
    setHome,
    addHomePending,
    addAwayPending,
    removeHomePending,
    removeAwayPending,
    clearPickedPlayers,
    clearPendingPickedPlayers,
} = pickedPlayersSlice.actions;
export default pickedPlayersSlice.reducer;

import { FC, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useAppSelector } from "../../Redux/store";
import Loading from "../04-Micros/Loading";
import { IUserLog } from "../../Types";
import { formatDate } from "../../Utils";
import { getUserLog } from "../../Api";

interface IProps {
    open: boolean;
    onClose: () => void;
}

const StatsModal: FC<IProps> = ({ open, onClose }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<IUserLog[]>([]);
    const [sortBy, setSortBy] = useState<
        | null
        | "username"
        | "role"
        | "logDate"
        | "logCnt"
        | "actionDate"
        | "actionCnt"
    >(null);

    const token = useAppSelector((state) => state.login.token.access_token);

    useEffect(() => {
        setIsLoading(true);
        getUserLog(token)
            .then((res) => {
                setUsers(res);
            })
            .catch((err) => console.log("Get user log err:", err))
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortByUsername = (a: IUserLog, b: IUserLog) => {
        const nameA = a.username.toLowerCase();
        const nameB = b.username.toLowerCase();
        if (nameA < nameB) return -1;
        else if (nameA > nameB) return 1;
        else return 0;
    };
    const sortByRole = (a: IUserLog, b: IUserLog) => {
        if (a.role < b.role) return -1;
        else if (a.role > b.role) return 1;
        else return 0;
    };
    const sortByLogDate = (a: IUserLog, b: IUserLog) => {
        const dateA = a.access ? new Date(a.access.accessAt).getTime() : 0;
        const dateB = b.access ? new Date(b.access.accessAt).getTime() : 0;

        return dateB - dateA;
    };
    const sortByLogCnt = (a: IUserLog, b: IUserLog) =>
        b.accessCount - a.accessCount;
    const sortByActionDate = (a: IUserLog, b: IUserLog) => {
        const dateA = a.activity
            ? new Date(a.activity.activityAt).getTime()
            : 0;
        const dateB = b.activity
            ? new Date(b.activity.activityAt).getTime()
            : 0;

        return dateB - dateA;
    };
    const sortByActionCnt = (a: IUserLog, b: IUserLog) =>
        b.activityCount - a.activityCount;

    const sortByFunctions = {
        username: sortByUsername,
        role: sortByRole,
        logDate: sortByLogDate,
        logCnt: sortByLogCnt,
        actionDate: sortByActionDate,
        actionCnt: sortByActionCnt,
    };

    const sortedUsers = sortBy
        ? [...users].sort(sortByFunctions[sortBy])
        : users;

    return (
        <>
            {isLoading && <Loading />}
            <Dialog
                className="StatsDetailModal"
                open={open}
                onClose={onClose}
                maxWidth={"lg"}
                fullWidth
            >
                <section className="StatsModal">
                    <h1>Uživatelské statistiky</h1>
                    <div className="StatsModal-tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    <th
                                        align="left"
                                        onClick={() => setSortBy("username")}
                                    >
                                        Uživatel
                                    </th>
                                    <th
                                        align="left"
                                        onClick={() => setSortBy("role")}
                                    >
                                        Role
                                    </th>
                                    <th
                                        align="center"
                                        onClick={() => setSortBy("logDate")}
                                    >
                                        Poslední přihlášení
                                    </th>
                                    <th
                                        align="center"
                                        onClick={() => setSortBy("logCnt")}
                                    >
                                        Počet přihlášení
                                    </th>
                                    <th
                                        align="center"
                                        onClick={() => setSortBy("actionDate")}
                                    >
                                        Poslední akce
                                    </th>
                                    <th
                                        align="center"
                                        onClick={() => setSortBy("actionCnt")}
                                    >
                                        Počet akcí
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedUsers.map((user) => (
                                    <tr key={user.username}>
                                        <td>{user.username}</td>
                                        <td>{user.role}</td>
                                        <td align="center">
                                            {user.access
                                                ? formatDate(
                                                      new Date(
                                                          user.access.accessAt
                                                      ),
                                                      "dd.MM.yyyy HH:mm"
                                                  )
                                                : "---"}
                                        </td>
                                        <td align="center">
                                            {user.accessCount}
                                        </td>
                                        <td align="center">
                                            {user.activity
                                                ? formatDate(
                                                      new Date(
                                                          user.activity.activityAt
                                                      ),
                                                      "dd.MM.yyyy HH:mm"
                                                  )
                                                : "---"}
                                        </td>
                                        <td align="center">
                                            {user.activityCount}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </Dialog>
        </>
    );
};

export default StatsModal;

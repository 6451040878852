import { FC, useEffect } from "react";
import Filter from "./Filter";
import { IFilter } from "../../Types";

interface IProps {
    filters: IFilter[];
    active: string;
    setActive: (value: string) => void;
}

const FiltersRow: FC<IProps> = ({ filters, active, setActive }) => {
    useEffect(() => {
        !active && setActive(filters[0].value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="FiltersRow">
            {filters.map((filter, index) => {
                return (
                    <Filter
                        key={index}
                        title={filter.label}
                        active={active === filter.value}
                        disabled={filter.disabled}
                        onClick={() => setActive(filter.value)}
                    />
                );
            })}
        </div>
    );
};

export default FiltersRow;

import { FC } from "react";
import { IVideo } from "../../Types";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { setActiveVideo } from "../../Redux/video";

interface IProps {
    videos: IVideo[];
}

const VideoPagination: FC<IProps> = ({ videos }) => {
    const dispatch = useAppDispatch();
    const active = useAppSelector((state) => state.video.value.active);

    const handleSetActive = (index: number) => {
        dispatch(setActiveVideo(index));
    };

    return (
        <div className="VideoPagination">
            {videos.map((e, index) => (
                <div
                    className={`VideoPagination-dot${
                        active === index ? " active" : ""
                    }`}
                    onClick={() => handleSetActive(index)}
                    key={index}
                ></div>
            ))}
        </div>
    );
};

export default VideoPagination;

//Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TNavigateUrl } from "../Types/navigation";
//Types

interface INavigationTab {
	value: TNavigateUrl;
}

const navigationTabInitialState: INavigationTab = {
	value: "overview",
};

export const navigationTabSlice = createSlice({
	name: "navigationTab",
	initialState: navigationTabInitialState,
	reducers: {
		navigateTo: (state, action: PayloadAction<TNavigateUrl>) => {
			state.value = action.payload;
		},
	},
});

export const { navigateTo } = navigationTabSlice.actions;
export default navigationTabSlice.reducer;

import { FC, useRef, useState } from "react";
import {
    EyeGrayIcon,
    EyeIcon,
    EyeOffGrayIcon,
    EyeOffIcon,
    LockGrayIcon,
    LockIcon,
    MailGrayIcon,
    MailIcon,
} from "../../Icons";

interface IProps {
    title: string;
    value: string;
    setValue: (newValue: string) => void;
    type: "email" | "password";
    placeholder?: string;
    handleEnter?: () => void;
    error?: boolean;
}

const LoginInput: FC<IProps> = ({
    title,
    placeholder,
    value,
    setValue,
    type,
    handleEnter,
    error,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [focused, setFocused] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleEnter!();
        }
    };

    const handleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const focusOnInput = () => {
        if (!inputRef?.current) return;

        inputRef.current.focus();
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if ((e.target as Element).className !== "LoginInput-input") {
            e.preventDefault();
        }
    };

    return (
        <div className="LoginInput">
            <p className="LoginInput-title">{title}</p>
            <div
                className={`LoginInput-inner${focused ? " focused" : ""}${
                    error ? " error" : ""
                }`}
                onClick={focusOnInput}
                onMouseDown={handleMouseDown}
            >
                {type === "email" &&
                    (focused ? (
                        <MailIcon height={24} />
                    ) : (
                        <MailGrayIcon height={24} />
                    ))}
                {type === "password" &&
                    (focused ? (
                        <LockIcon height={24} />
                    ) : (
                        <LockGrayIcon height={24} />
                    ))}
                <input
                    className="LoginInput-input"
                    ref={inputRef}
                    type={type === "password" && showPassword ? "text" : type}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleEnter ? handleKeyDown : undefined}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
                {type === "password" && (
                    <div
                        className="LoginInput-showPassword"
                        onClick={handleShowPassword}
                    >
                        {focused ? (
                            showPassword ? (
                                <EyeIcon height={24} />
                            ) : (
                                <EyeOffIcon height={24} />
                            )
                        ) : showPassword ? (
                            <EyeGrayIcon height={24} />
                        ) : (
                            <EyeOffGrayIcon height={24} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginInput;

import { FC } from "react";

interface IProps {
	homeTeam: string;
	homeScore: number;
	awayTeam: string;
	awayScore: number;
}

const MatchInfoHeader: FC<IProps> = ({ homeTeam, homeScore, awayTeam, awayScore }) => {
	return (
		<div className="MatchInfoHeader">
			<div className="MatchInfoHeader-team">
				<img src={require(`../../Assets/logos/${homeTeam}.png`)} alt="team logo" />
				<p>{homeTeam}</p>
			</div>
			<div className="MatchInfoHeader-score">
				<p>
					{homeScore}:{awayScore}
				</p>
			</div>
			<div className="MatchInfoHeader-team">
				<p>{awayTeam}</p>
				<img src={require(`../../Assets/logos/${awayTeam}.png`)} alt="team logo" />
			</div>
		</div>
	);
};

export default MatchInfoHeader;

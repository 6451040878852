import { FC } from "react";
import Buttons from "../04-Micros/Buttons";
import Filter from "../04-Micros/Filter";
import DetailShotDot from "../04-Micros/DetailShotDot";
import { Dialog } from "@mui/material";
import {
    DotBlueIcon,
    DotGrayIcon,
    DotGreenIcon,
    DotRedIcon,
} from "../../Icons";
import { IPlayer, IShot } from "../../Types";
import {
    calculateShotPosition,
    calculateXGDecimal,
    filterShotsByType,
    getShotDetailModalType,
} from "../../Utils";

interface IProps {
    open: boolean;
    activeTeam: string;
    homeShortcut: string;
    awayShortcut: string;
    data: IShot[];
    players: IPlayer[];
    onClose: () => void;
    onTeamChange: (team: string) => void;
}

const ShotsDetailModal: FC<IProps> = ({
    open,
    activeTeam,
    homeShortcut,
    awayShortcut,
    data,
    players,
    onClose,
    onTeamChange,
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
            <section className="ShotsModal ShotsDetailModal">
                <div className="ShotsModal-header">
                    <div className="ShotsModal-header--team">
                        {homeShortcut && (
                            <img
                                src={require(`../../Assets/logos/${homeShortcut}.png`)}
                                alt="team logo"
                            />
                        )}
                        <p>{homeShortcut}</p>
                    </div>
                    <div className="ShotsModal-header--activeTeam">
                        <Filter
                            paddingHorizontal={26}
                            title={homeShortcut}
                            active={activeTeam === homeShortcut}
                            onClick={() => onTeamChange(homeShortcut)}
                        />
                        <Filter
                            paddingHorizontal={26}
                            title={awayShortcut}
                            active={activeTeam === awayShortcut}
                            onClick={() => onTeamChange(awayShortcut)}
                        />
                    </div>
                    <div className="ShotsModal-header--team">
                        <p>{awayShortcut}</p>
                        {awayShortcut && (
                            <img
                                src={require(`../../Assets/logos/${awayShortcut}.png`)}
                                alt="team logo"
                            />
                        )}
                    </div>
                </div>
                <div className="ShotsDetailModal-body">
                    <div className="ShotsDetailModal-body--container">
                        <img
                            src={require("../../Assets/hriste.png")}
                            alt="goal"
                        />
                        {data.map((shot, index) => {
                            const type = getShotDetailModalType(shot.result);
                            const player = players.find(
                                (player) => shot.playerId === player.id
                            );
                            const id = player ? player.id : "";
                            const jersey = player ? player.jersey : "";
                            const name = player ? player.name : "";
                            const surname = player ? player.surname : "";
                            const position = calculateShotPosition(
                                shot.x,
                                shot.y
                            );

                            return (
                                <DetailShotDot
                                    key={index}
                                    top={position.y}
                                    left={position.x}
                                    id={id}
                                    jersey={jersey}
                                    name={name}
                                    surname={surname}
                                    time={shot.time}
                                    xG={calculateXGDecimal(shot.xG)}
                                    type={type}
                                />
                            );
                        })}
                    </div>
                    <div className="ShotsDetailModal-body--stats">
                        <div className="ShotsDetailModal-body--statsItem">
                            <p>
                                <DotBlueIcon /> Vstřelené góly
                            </p>
                            <h3>{filterShotsByType(data, "goal").length}</h3>
                        </div>
                        <div className="ShotsDetailModal-body--statsItem">
                            <p>
                                <DotRedIcon /> Chycené střely
                            </p>
                            <h3>{filterShotsByType(data, "saved").length}</h3>
                        </div>
                        <div className="ShotsDetailModal-body--statsItem">
                            <p>
                                <DotGrayIcon /> Střely mimo
                            </p>
                            <h3>
                                {
                                    [
                                        ...filterShotsByType(data, "missed"),
                                        ...filterShotsByType(data, "post"),
                                    ].length
                                }
                            </h3>
                        </div>
                        <div className="ShotsDetailModal-body--statsItem">
                            <p>
                                <DotGreenIcon /> Zablokované střely
                            </p>
                            <h3>
                                {filterShotsByType(data, "deflected").length}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="ShotsModal-buttons">
                    <Buttons
                        title="Zavřít"
                        type="outlined"
                        color="black"
                        maxWidth={128}
                        onClick={onClose}
                    />
                </div>
            </section>
        </Dialog>
    );
};

export default ShotsDetailModal;

import { useEffect, useState } from "react";

export const usePagination = (itemsOnPage: number, dataLength: number) => {
	const [maxPagination, setMaxPagination] = useState<number>(1);
	const [pagination, setPagination] = useState<number>(1);

	const getMinIndex = () => {
		return (pagination - 1) * itemsOnPage;
	};

	const getMaxIndex = () => {
		return pagination * itemsOnPage;
	};

	const moveToPrevious = () => {
		if (pagination > 1) setPagination((prevState) => prevState - 1);
	};

	const moveToNext = () => {
		if (pagination < maxPagination) setPagination((prevState) => prevState + 1);
	};

	useEffect(() => {
		setMaxPagination(Math.ceil(dataLength / itemsOnPage));
	}, [dataLength, itemsOnPage]);

	return { pagination, maxPagination, getMinIndex, getMaxIndex, moveToPrevious, moveToNext };
};

import {
    IGameState,
    IGoal,
    IHeadToHead,
    IHeadToHeadPlayer,
    IHeadToHeadTeammate,
    IIceTimeOpponent,
    IIceTimeOptions,
    IIceTimePlayer,
    IInterval,
    IIntervalWithColor,
    IPlayer,
    IShift,
    IState,
} from "../Types";
import { getIntervalIntersection } from "./common";
import { getPlayerById, getThirdsStart, isInTime } from "./matches";

export const getTimeline = (value: string) => {
    let ret: string[] = [];
    const third = Math.floor(getThirdsStart(value) / 1200);

    for (let i = 0; i <= 20; i += 2) {
        const min = 20 * third + i;
        ret.push(`${min < 10 ? "0" + min : min}:00`);
    }

    return ret;
};

export const prepareTeamIceTime = (roster: IPlayer[]) => {
    const backwards = roster
        .filter((player) => player.position === "backward")
        .sort((a: IPlayer, b: IPlayer) => Number(a.jersey) - Number(b.jersey));
    const forwards = roster
        .filter((player) => player.position === "forward")
        .sort((a: IPlayer, b: IPlayer) => Number(a.jersey) - Number(b.jersey));

    return [...backwards, ...forwards];
};

export const prepareIceTimeData = (
    data: IHeadToHead[],
    homePlayers: IPlayer[],
    awayPlayers: IPlayer[],
    main: "home" | "away"
) => {
    return [
        ...prepareTeamIceTime(
            data.map((item) => ({
                ...getPlayerById(
                    item.playerId,
                    main === "home" ? homePlayers : awayPlayers
                )!,
                metrics: item.metrics,
                teammates: item.teammates,
                opponents: [
                    ...prepareTeamIceTime(
                        item.opponents.map((opponent) =>
                            prepareIceTimeOpponent(
                                opponent,
                                main === "home" ? awayPlayers : homePlayers
                            )
                        )
                    ),
                ],
            }))
        ),
    ] as IIceTimePlayer[];
};

export const prepareIceTimeOpponent = (
    data: IHeadToHeadPlayer,
    roster: IPlayer[]
) => {
    const player = getPlayerById(data.playerId, roster);
    return { ...player!, metrics: data.metrics };
};

export const getMaxIceTimeOfPlayer = (player: IIceTimePlayer) => {
    if (!player.opponents.length) return 0;
    const max = player.opponents.reduce((prev, current) => {
        if (current.metrics === null) return prev;
        if (prev.metrics === null) return current;
        return prev.metrics.TOI > current.metrics.TOI ? prev : current;
    });
    return max.metrics ? max.metrics.TOI : 0;
};

export const getMaxIceTime = (data: IIceTimePlayer[]) => {
    const max = data.reduce((prev, current) =>
        getMaxIceTimeOfPlayer(prev) > getMaxIceTimeOfPlayer(current)
            ? prev
            : current
    );
    return max.metrics ? max.metrics.TOI : 0;
};

export const getToiWithPlayer = (
    playerId: string,
    withId: string,
    data: IIceTimePlayer[],
    type: "teammate" | "opponent"
) => {
    const withPlayer = data.find((player) => player.id === withId);
    if (withPlayer) {
        if (type === "teammate")
            return getToiTeammate(playerId, withPlayer.teammates);
        else return getToiOpponent(playerId, withPlayer.opponents);
    }
    return null;
};

export const getToiOpponent = (playerId: string, data: IIceTimeOpponent[]) => {
    const res = data.find((player) => player.id === playerId);
    return res?.metrics?.TOI;
};

export const getToiTeammate = (
    playerId: string,
    data: IHeadToHeadTeammate[]
) => {
    const res = data.find((player) => player.playerId.id === playerId);
    return res?.metrics.TOI;
};

export const getPlayerToiInGameType = (
    gameStates: IGameState[],
    shifts: IShift[],
    type: "PP" | "RG" | "SH",
    options: IIceTimeOptions
) => {
    let toi: number = 0;
    const filteredStates = gameStates.filter((state) => state.type === type);
    shifts.forEach((shift) => {
        filteredStates.forEach((state) => {
            const start =
                shift[0].from >= options.start! ? shift[0].from : options.start;
            const end = shift[0].to <= options.end! ? shift[0].to : options.end;
            toi += getIntervalIntersection(state, {
                start: start !== undefined ? start : shift[0].from,
                end: end !== undefined ? end : shift[0].to,
            });
        });
    });

    return toi;
};

export const getLeftValueOnTimeline = (
    time: number,
    width: number,
    increaseBy: number = 8
) => {
    const percent = (width - increaseBy * 2) / 1200;
    const start = time % 1200;

    return {
        left: `${start * percent + increaseBy}px`,
    };
};

export const getPositionOnTimeline = (
    interval: IInterval,
    width: number,
    increaseBy: number = 8
) => {
    const percent = (width - increaseBy * 2) / 1200;
    const start = interval.start % 1200;
    let end = interval.end % 1200;
    if (interval.end !== interval.start) {
        end = end === 0 ? 1200 : end;
    }

    return {
        left: `${start * percent + increaseBy}px`,
        width: `${(end - start) * percent}px`,
    };
};

export const getStateColor = (gameState: IGameState, opacity: number = 0.1) => {
    let color = "";
    const singlePlayerPP: IState[] = ["3:4", "4:3", "4:5", "5:4", "5:6", "6:5"];

    switch (gameState.type) {
        case "PP":
            color = `rgba(9, 146, 104, ${
                singlePlayerPP.includes(gameState.state) ? opacity : 0.2
            })`;
            break;
        case "SH":
            color = `rgb(224, 49, 49, ${
                singlePlayerPP.includes(gameState.state) ? opacity : 0.2
            })`;
            break;
        default:
            color = `rgb(134, 142, 150, ${opacity})`;
            break;
    }

    return color;
};

export const getColorOnTimeline = (
    gameState: IGameState,
    opacity: number = 0.1
) => {
    let color = getStateColor(gameState, opacity);
    return color ? { backgroundColor: color } : { display: "none" };
};

export const splitShiftByStates = (shift: IShift, gameStates: IGameState[]) => {
    const result: IIntervalWithColor[] = [];
    let currentStart = shift[0].from;
    let currentEnd = shift[0].to;

    for (const interval of gameStates) {
        if (interval.end <= currentStart || interval.start >= currentEnd) {
            continue;
        }

        if (interval.start >= currentStart) {
            result.push({
                start: currentStart,
                end: interval.start,
                color: "#0b0d0e",
            });
            currentStart = interval.start;
        }

        if (interval.end >= currentEnd) {
            result.push({
                start: currentStart,
                end: currentEnd,
                color: getStateColor(interval, 1),
            });
            currentStart = currentEnd;
        }

        if (interval.end <= currentEnd) {
            result.push({
                start: currentStart,
                end: interval.end,
                color: getStateColor(interval, 1),
            });
            currentStart = interval.end;
        } else {
            currentStart = currentEnd;
        }
    }

    if (currentStart < currentEnd) {
        result.push({ start: currentStart, end: currentEnd, color: "#0b0d0e" });
    }

    return result;
};

export const getShiftsByState = (
    shifts: IShift[],
    gameStates: IGameState[]
) => {
    let res: IIntervalWithColor[] = [];
    shifts.forEach((shift) => {
        res = [...res, ...splitShiftByStates(shift, gameStates)];
    });

    return res;
};

export const getTeamDetailMark = (goal: IGoal, playerId: string) => {
    if (goal.playerId === playerId) return "G";
    else if (goal.assist1 === playerId || goal.assist2 === playerId) return "A";
    else return "+";
};

export const prepareShifts = (shifts: IShift[], options: IIceTimeOptions) => {
    return shifts
        .filter(
            (shift) =>
                isInTime(options.start!, options.end!, shift[0].from + 1) ||
                isInTime(options.start!, options.end!, shift[0].to)
        )
        .map((shift) => {
            const ret: IShift = [
                {
                    from:
                        shift[0].from < options.start!
                            ? options.start! - 1
                            : shift[0].from,
                    to: shift[0].to > options.end! ? options.end! : shift[0].to,
                },
            ];

            return ret;
        });
};

import { FC } from "react";
import { IDetailData } from "../../Types";
import { getPlayerPosition } from "../../Utils";
import { PlayIcon } from "../../Icons";
import { useAppDispatch } from "../../Redux/store";
import { fillVideoData, toggleVideo } from "../../Redux/video";

interface IProps extends IDetailData {
    index: number;
    switchPlace?: boolean;
    fixed?: number;
}

const StatsDetailRow: FC<IProps> = ({
    index,
    switchPlace,
    jersey,
    name,
    surname,
    position,
    value,
    formattedValue,
    fixed,
    videos,
}) => {
    const dispatch = useAppDispatch();

    const handlePlayVideo = () => {
        dispatch(toggleVideo());
        dispatch(fillVideoData(videos));
    };

    return (
        <div className={`StatsDetailRow${switchPlace ? " switchPlace" : ""}`}>
            <div className="StatsDetailRow-playerInfo">
                <div
                    className={`StatsDetailRow-playIcon${
                        videos.length === 0 ? " disabled" : ""
                    }`}
                    onClick={videos.length > 0 ? handlePlayVideo : undefined}
                >
                    <PlayIcon />
                </div>
                <div className="StatsDetailRow-playerText">
                    <p className="StatsDetailRow-playerText--player">
                        #{jersey} <span>{name}</span> {surname}
                    </p>
                    <p className="StatsDetailRow-playerText--role">
                        {getPlayerPosition(position)}
                    </p>
                </div>
            </div>
            <div
                className={`StatsDetailRow-stat color${
                    value === 0 ? 4 : index
                }`}
            >
                {formattedValue
                    ? formattedValue
                    : fixed
                    ? value.toFixed(fixed)
                    : value}
            </div>
        </div>
    );
};

export default StatsDetailRow;

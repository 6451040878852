import { useEffect, useState } from "react";

import { useGetFormations } from "../../Hooks";
import { useAppSelector } from "../../Redux/store";
import { IFormations, IFormation, IShotsByTeam, IMatch } from "../../Types";
import {
    filterShots,
    filterShotsBySelectedThird,
    getFormationPlayers,
    getFormationTypeFilters,
    getShotsByLineUp,
    getShotsOfFormation,
    getTeamFilters,
    sortShotsByTeam,
} from "../../Utils";
import FormationsRow from "../02-Macros/FormationsRow";
import FiltersContainer from "../03-Partials/FiltersContainer";
import FiltersRow from "../04-Micros/FiltersRow";
import Loading from "../04-Micros/Loading";

const Formations = () => {
    const match = useAppSelector((state) => state.match.value);

    const [teamActive, setTeamActive] = useState<string>(
        match.homeTeam.shortcut
    );
    const [typeActive, setTypeActive] = useState<string>("forwards");
    // const [thirdsActive, setThirdsActive] = useState<string>("All");
    const [shots, setShots] = useState<IShotsByTeam[]>([]);
    const [topFormations, setTopFormations] = useState<IFormation[]>([]);

    const { formations, isLoading } = useGetFormations(match.id);

    useEffect(() => {
        if (!formations) return;

        const sliceSize = typeActive === "forwards" ? 4 : 3;
        const topFormations = getTOPFormations(
            sortFormations(getFormationsByTeamAndType(formations)),
            sliceSize
        );
        const shots = filterShotsByFormationAndLineUp(
            match,
            topFormations,
            initialTeamShots,
            teamActive,
            "All"
        );

        setTopFormations(topFormations);
        setShots(shots);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formations, typeActive, teamActive, "All"]);

    const initialTeamShots = sortShotsByTeam(match, filterShots(match.shot));

    const sortFormations = (formations: IFormation[]): IFormation[] =>
        [...formations].sort((a, b) => b.metrics.TOI - a.metrics.TOI);

    const getTOPFormations = (
        formations: IFormation[],
        sliceSize: number
    ): IFormation[] => {
        if (!formations) return [];
        return [...formations].slice(0, sliceSize);
    };

    const filterShotsByFormationAndLineUp = (
        match: IMatch,
        formation: IFormation[],
        initialTeamShots: IShotsByTeam,
        teamActive: string,
        thirdsActive: string
    ) => {
        // Filtering shots by formation
        const formationShots: IShotsByTeam[] = [...formation].map((formation) =>
            getShotsOfFormation(formation, match, teamActive, initialTeamShots)
        );

        // Filtering shots to situation 5:5
        const lineUpShots = formationShots.map((shot) =>
            getShotsByLineUp(
                match.homeTeam.gameState,
                match.awayTeam.gameState,
                shot,
                "5:5"
            )
        );

        // Filtering shots by selected third
        const shots = lineUpShots.map((shot) =>
            filterShotsBySelectedThird(thirdsActive, shot)
        );

        return shots;
    };

    const getFormationsByTeamAndType = (formations: IFormations) => {
        return teamActive === match.homeTeam.shortcut
            ? typeActive === "forwards"
                ? formations.home.forwards
                : formations.home.backwards
            : typeActive === "forwards"
            ? formations.away.forwards
            : formations.away.backwards;
    };

    return (
        <>
            <FiltersContainer>
                <FiltersRow
                    filters={getTeamFilters(match)}
                    active={teamActive}
                    setActive={setTeamActive}
                />
                <FiltersRow
                    filters={getFormationTypeFilters()}
                    active={typeActive}
                    setActive={setTypeActive}
                />
                {/* <FiltersRow
                    filters={getThirdsFilters(match.length, match.game)}
                    active={thirdsActive}
                    setActive={setThirdsActive}
                /> */}
            </FiltersContainer>
            <main className="Formations">
                {typeActive && (
                    <>
                        {isLoading && <Loading />}
                        {topFormations.map((formation, index) => {
                            const players = getFormationPlayers(
                                formation.players,
                                teamActive === match.homeTeam.shortcut
                                    ? match.homeTeam.roster
                                    : match.awayTeam.roster
                            );

                            return (
                                <FormationsRow
                                    key={index}
                                    players={players}
                                    metrics={formation.metrics}
                                    shots={shots[index]}
                                    teamActive={
                                        teamActive === match.homeTeam.shortcut
                                            ? "home"
                                            : "away"
                                    }
                                />
                            );
                        })}
                    </>
                )}
            </main>
        </>
    );
};

export default Formations;

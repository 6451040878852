import { FC } from "react";

interface IProps {
    jersey: string;
    firstName: string;
    lastName: string;
    shots: number;
    goals: number;
    active?: boolean;
    onClick?: () => void;
}

const PickedPlayer: FC<IProps> = ({
    jersey,
    firstName,
    lastName,
    shots,
    goals,
    active,
    onClick,
}) => {
    return (
        <div
            className={`PickedPlayer${active ? " active" : ""}`}
            onClick={onClick}
        >
            <div className="PickedPlayer-info">
                <p>{jersey}</p>
                <p>
                    <span>{firstName}</span> {lastName}
                </p>
            </div>
            <p className="PickedPlayer-stats">
                S<span>{shots}</span>/G<span>{goals}</span>
            </p>
        </div>
    );
};

export default PickedPlayer;

import { FC, useRef, useState, useLayoutEffect } from "react";
import Buttons from "./Buttons";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { fillVideoData, toggleVideo } from "../../Redux/video";
import { formatSeconds, formatShotVideo } from "../../Utils";

interface IProps {
    id: string;
    jersey: string;
    name: string;
    surname: string;
    time: number;
    xG?: number;
    compensateLeft?: number;
    compensateTop?: number;
}

const ShotsPopup: FC<IProps> = ({
    id,
    jersey,
    name,
    surname,
    xG,
    time,
    compensateLeft = 1,
    compensateTop = 22,
}) => {
    const dispatch = useAppDispatch();
    const match = useAppSelector((state) => state.match.value);

    const ref = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState<number | null>(null);
    const [top, setTop] = useState<number | null>(null);

    useLayoutEffect(() => {
        if (ref.current) {
            setTop(-(ref.current.offsetHeight + compensateTop));
            setLeft(-(ref.current.offsetWidth / 2 + compensateLeft));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleVideo = () => {
        dispatch(toggleVideo());
        dispatch(
            fillVideoData(
                formatShotVideo(id, jersey, name, surname, time, match)
            )
        );
    };

    return (
        <div
            className="ShotsPopup"
            ref={ref}
            style={{
                top: top ? top : 0,
                left: left ? left : 0,
            }}
        >
            <p className="ShotsPopup-player">
                {jersey} {name} <span>{surname}</span>
            </p>
            <div className="ShotsPopup-stats">
                <p>
                    Čas: <span>{formatSeconds(time)}</span>
                </p>
                {xG !== undefined && (
                    <p>
                        xG: <span>{xG.toFixed(2)}</span>
                    </p>
                )}
            </div>
            <Buttons
                title="Video"
                type="outlined"
                color="white"
                maxWidth={128}
                fontSize={10}
                icon={<div className="ShotsPopup-video"></div>}
                onClick={handleVideo}
            />
        </div>
    );
};

export default ShotsPopup;

import { IIdentityResponse } from "../Types/login";
import { axiosInstance } from "./axiosInstance";

export const getIdentity = async (token: string) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const response: IIdentityResponse = await axiosInstance.get("/me", {
        headers,
    });
    return response;
};

import { FC } from "react";
import { IPlayer } from "../../Types";

export interface IPlayerPickerItemProps {
    player: IPlayer;
    onItemClick: () => void;
    active?: boolean;
}

const PlayerPickerItem: FC<IPlayerPickerItemProps> = ({
    player,
    onItemClick,
    active = false,
}) => {
    return (
        <div
            className={`PlayerPickerItem${active ? " active" : ""}`}
            onClick={onItemClick}
        >
            <div className="PlayerPickerItem-number">{player.jersey}</div>
            <p className="PlayerPickerItem-player">
                <span>{player.name}</span>
                <br />
                {player.surname}
            </p>
        </div>
    );
};

export default PlayerPickerItem;

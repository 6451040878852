import { FC, useEffect } from "react";
import { navigateTo } from "../../Redux/navigation";
import { useAppSelector, useAppDispatch } from "../../Redux/store";
import { TNavigateUrl } from "../../Types/navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setIdentity, setSessionToken } from "../../Redux/login";
import { getSessionWithExpiration } from "../../Utils";
import { getIdentity } from "../../Api";

interface IProps {
    children: JSX.Element;
}

const ProtectedRoute: FC<IProps> = ({ children }) => {
    const isLogged = useAppSelector((state) => state.login.logged);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isLogged) {
            handleSession();
        }
        dispatch(
            navigateTo(location.pathname.replace("/", "") as TNavigateUrl)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleSession = () => {
        const token = getSessionWithExpiration("token");
        if (token) {
            dispatch(setSessionToken(token));
            getIdentity(token)
                .then((res) => {
                    dispatch(setIdentity(res.data));
                })
                .catch((err) => console.log("Get identity err:", err));
        } else {
            navigate("/login");
            toast.error("Platnost přihlášení vypršela.", {
                toastId: "loginExpired",
            });
        }
    };

    return children;
};

export default ProtectedRoute;

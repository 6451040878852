//React
import React from "react";
//Redux
import { useAppSelector } from "./Redux/store";
// Styles
import "./SCSS/02-Components/01-Pages/_login.scss";
import "./SCSS/02-Components/01-Pages/_matches.scss";
import "./SCSS/02-Components/01-Pages/_overview.scss";
import "./SCSS/02-Components/01-Pages/_shots.scss";
import "./SCSS/02-Components/01-Pages/_goalkeepers.scss";
import "./SCSS/02-Components/01-Pages/_formations.scss";
import "./SCSS/02-Components/01-Pages/_faceOff.scss";
import "./SCSS/02-Components/01-Pages/_iceTime.scss";
import "./SCSS/02-Components/01-Pages/_headToHead.scss";
import "./SCSS/02-Components/01-Pages/_momentum.scss";
import "./SCSS/02-Components/02-Macros/_mainNavigation.scss";
import "./SCSS/02-Components/02-Macros/_layout.scss";
import "./SCSS/02-Components/02-Macros/_header.scss";
import "./SCSS/02-Components/02-Macros/_statsBigContainer.scss";
import "./SCSS/02-Components/02-Macros/_statsSmallContainer.scss";
import "./SCSS/02-Components/02-Macros/_playerPickerModal.scss";
import "./SCSS/02-Components/02-Macros/_shotsDetailModal.scss";
import "./SCSS/02-Components/02-Macros/_shotsModalsGeneral.scss";
import "./SCSS/02-Components/02-Macros/_formationsRow.scss";
import "./SCSS/02-Components/02-Macros/_statsModal.scss";
import "./SCSS/02-Components/02-Macros/_headToHeadCharts.scss";
import "./SCSS/02-Components/02-Macros/_momentumCharts.scss";
import "./SCSS/02-Components/03-Partials/_topHeader.scss";
import "./SCSS/02-Components/03-Partials/_matchInfoHeader.scss";
import "./SCSS/02-Components/03-Partials/_filtersContainer.scss";
import "./SCSS/02-Components/03-Partials/_statsBox.scss";
import "./SCSS/02-Components/03-Partials/_statsDetailModal.scss";
import "./SCSS/02-Components/03-Partials/_shotsMap.scss";
import "./SCSS/02-Components/03-Partials/_statsBoxSimple.scss";
import "./SCSS/02-Components/03-Partials/_goalkeepersMap.scss";
import "./SCSS/02-Components/03-Partials/_matchInfo.scss";
import "./SCSS/02-Components/03-Partials/_playerPickerRow.scss";
import "./SCSS/02-Components/03-Partials/_faceOffMap.scss";
import "./SCSS/02-Components/03-Partials/_sidebar.scss";
import "./SCSS/02-Components/03-Partials/_iceTimeTimeline.scss";
import "./SCSS/02-Components/03-Partials/_iceTimeRow.scss";
import "./SCSS/02-Components/03-Partials/_iceTimeShift.scss";
import "./SCSS/02-Components/03-Partials/_headToHeadTeamPanel.scss";
import "./SCSS/02-Components/03-Partials/_changelogModal.scss";
import "./SCSS/02-Components/03-Partials/_momentumBar.scss";
import "./SCSS/02-Components/03-Partials/_momentumGoal.scss";
import "./SCSS/02-Components/03-Partials/_videoModal.scss";
import "./SCSS/02-Components/04-Micros/_filter.scss";
import "./SCSS/02-Components/04-Micros/_statsDetailHeader.scss";
import "./SCSS/02-Components/04-Micros/_statsDetailRow.scss";
import "./SCSS/02-Components/04-Micros/_buttons.scss";
import "./SCSS/02-Components/04-Micros/_input.scss";
import "./SCSS/02-Components/04-Micros/_goalkeepersMapStats.scss";
import "./SCSS/02-Components/04-Micros/_playerPickerItem.scss";
import "./SCSS/02-Components/04-Micros/_pickedPlayer.scss";
import "./SCSS/02-Components/04-Micros/_shotsPopup.scss";
import "./SCSS/02-Components/04-Micros/_selectTab.scss";
import "./SCSS/02-Components/04-Micros/_detailShotDot.scss";
import "./SCSS/02-Components/04-Micros/_loading.scss";
import "./SCSS/02-Components/04-Micros/_paginationButton.scss";
import "./SCSS/02-Components/04-Micros/_switch.scss";
import "./SCSS/02-Components/04-Micros/_pieChartWrapper.scss";
import "./SCSS/02-Components/04-Micros/_loginInput.scss";
import "./SCSS/02-Components/04-Micros/_videoHeader.scss";
import "./SCSS/02-Components/04-Micros/_videoPagination.scss";
//Components
import Login from "./Components/01-Pages/Login";
import Matches from "./Components/01-Pages/Matches";
import Layout from "./Components/02-Macros/Layout";
import { ToastContainer } from "react-toastify";
//Material UI
import { TabContext } from "@mui/lab";
//Others
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoute from "./Components/02-Macros/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/matches",
        element: <ProtectedRoute children={<Matches />} />,
    },
    {
        path: "/overview",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/shots",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/formations",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/goalkeepers",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/faceOff",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/iceTime",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/h2h",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/predictions",
        element: <ProtectedRoute children={<Layout />} />,
    },
    {
        path: "/momentum",
        element: <ProtectedRoute children={<Layout />} />,
    },
]);

const App = () => {
    const tabValue = useAppSelector((state) => state.navigationTab.value);

    return (
        <TabContext value={tabValue}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={5}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <RouterProvider router={router} />
        </TabContext>
    );
};

export default App;

import { LoadingIcon } from "../../Icons";

const Loading = () => {
	return (
		<div>
			<div className="Loading" data-testid="loading-component">
				<div className="Loading-inner">
					<LoadingIcon />
					<span>Načítání dat.</span>
				</div>
			</div>
		</div>
	);
};

export default Loading;

import { FC } from "react";
import Filter from "../04-Micros/Filter";
import PlayerPickerItem, {
    IPlayerPickerItemProps,
} from "../04-Micros/PlayerPickerItem";

interface IProps {
    title?: string;
    data: IPlayerPickerItemProps[];
    onRowClick: (data: IPlayerPickerItemProps[]) => void;
    onItemClick: (player: IPlayerPickerItemProps) => void;
}

const PlayerPickerRow: FC<IProps> = ({
    title,
    data,
    onRowClick,
    onItemClick,
}) => {
    const offesnsivePositionMap: Map<string, number> = new Map();
    const deffesnsivePositionMap: Map<string, number> = new Map();
    offesnsivePositionMap.set("LW", 1);
    offesnsivePositionMap.set("CE", 2);
    offesnsivePositionMap.set("RW", 3);
    deffesnsivePositionMap.set("LD", 1);
    deffesnsivePositionMap.set("RD", 2);

    const isFilterActive = data.find((player) => !player.active) === undefined;
    const sortedPlayers = data.sort((a, b) => {
        const positionA = offesnsivePositionMap.has(a.player.lineUp.position)
            ? offesnsivePositionMap.get(a.player.lineUp.position)
            : deffesnsivePositionMap.get(a.player.lineUp.position);
        const positionB = offesnsivePositionMap.has(b.player.lineUp.position)
            ? offesnsivePositionMap.get(b.player.lineUp.position)
            : deffesnsivePositionMap.get(b.player.lineUp.position);
        return positionA && positionB ? positionA - positionB : 0;
    });

    return (
        <div className="PlayerPickerRow">
            <Filter
                title={title}
                active={isFilterActive}
                onClick={() => onRowClick(data)}
                paddingHorizontal={38}
                additionalClass={"player-row"}
            />
            <div className="PlayerPickerRow-players">
                {sortedPlayers.map((player) => (
                    <PlayerPickerItem
                        key={player.player.id}
                        player={player.player}
                        active={player.active}
                        onItemClick={() => onItemClick(player)}
                    />
                ))}
            </div>
        </div>
    );
};

export default PlayerPickerRow;

import {
    IFaceOff,
    IMatch,
    IPlayer,
    IShot,
    IShotResult,
    IVideo,
} from "../Types";
import { getPlayerFaceOff } from "./faceOff";
import { getGameStateByTime, getTeamsGameStates } from "./matches";
import { getPlayerShots } from "./shots";

export const getShotType = (result: IShotResult) => {
    switch (result) {
        case "goal":
            return "Gól";
        case "saved":
            return "Chycená střela";
        case "deflected":
            return "Zablokovaná střela";
        default:
            return "Střela mimo";
    }
};

export const formatShotVideo = (
    id: string,
    jersey: string,
    name: string,
    surname: string,
    time: number,
    match: IMatch
): IVideo[] => {
    const shot = match.shot.find(
        (item) => item.playerId === id && Math.abs(item.time - time) <= 3
    );

    return [
        {
            player: { jersey, name, surname },
            time,
            type: getShotType(shot!.result),
            gameState: getGameStateByTime(getTeamsGameStates(id, match), time),
            danger: shot?.shotDanger,
            xG: shot?.xG,
        },
    ];
};

export const formatFaceOffVideo = (
    id: string,
    jersey: string,
    name: string,
    surname: string,
    time: number,
    match: IMatch,
    winner: boolean
): IVideo[] => {
    return [
        {
            player: { jersey, name, surname },
            time,
            faceOffTime: time + 1,
            type: `${winner ? "Vyhrané" : "Prohrané"} vhazování`,
            gameState: getGameStateByTime(getTeamsGameStates(id, match), time),
        },
    ];
};

export const getPlayerShotVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array);
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerShotOnGoalVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array).filter(
        (shot) => shot.result === "goal" || shot.result === "saved"
    );
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerSlotShotVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array).filter(
        (shot) => shot.inSlot
    );
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerMissedShotVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array).filter(
        (shot) => shot.result === "missed"
    );
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerHighDangerShotVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array).filter(
        (shot) => shot.shotDanger === "HD"
    );
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerLowDangerShotVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array).filter(
        (shot) => shot.shotDanger === "LD"
    );
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerBlockedShotVideos = (
    player: IPlayer,
    array: IShot[],
    match: IMatch
) => {
    const shots = getPlayerShots(player.id, array).filter(
        (shot) => shot.result === "deflected"
    );
    return [
        ...shots.map((shot) =>
            formatShotVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                shot.time,
                match
            )
        ),
    ].flat();
};

export const getPlayerFaceOffVideos = (
    player: IPlayer,
    array: IFaceOff[],
    match: IMatch
) => {
    console.log(getPlayerFaceOff(player.id, array));

    return [
        ...getPlayerFaceOff(player.id, array).map((faceOff) =>
            formatFaceOffVideo(
                player.id,
                player.jersey,
                player.name,
                player.surname,
                faceOff.time,
                match,
                `${faceOff.winnerId.id}` === player.id
            )
        ),
    ].flat();
};

import { FC } from "react";
import Buttons from "../04-Micros/Buttons";
import PickedPlayer from "../04-Micros/PickedPlayer";
import Shot from "../04-Micros/Shot";
import { IPlayer, IShot } from "../../Types";
import {
    calculateShotPosition,
    filterShotsByPlayerId,
    filterShotsByType,
    getPlayerById,
    getShotsOfSelectedPlayer,
} from "../../Utils";

interface IProps {
    team: string;
    filter: string;
    playerPicker: (team: string) => void;
    shotsDetail: (team: string) => void;
    data: IShot[];
    pickedPlayers: IPlayer[];
    selectedPlayer: string;
    setSelectedPlayer: (id: string) => void;
}

const ShotsMap: FC<IProps> = ({
    team,
    filter,
    playerPicker,
    shotsDetail,
    data,
    pickedPlayers,
    selectedPlayer,
    setSelectedPlayer,
}) => {
    const handleSelectPlayer = (id: string) => {
        if (selectedPlayer === id) {
            setSelectedPlayer("");
        } else {
            setSelectedPlayer(id);
        }
    };

    const handlePlayerPicker = () => {
        playerPicker(team);
    };

    const handleShotsDetail = () => {
        shotsDetail(team);
    };

    const selected = getPlayerById(selectedPlayer, pickedPlayers);
    const filteredData = getShotsOfSelectedPlayer(data, selectedPlayer);

    return (
        <div className="ShotsMap">
            <h3 className="ShotsMap-title">
                {selected === null && selectedPlayer !== ""
                    ? `${team}`
                    : selected !== null
                    ? `${team}: ${selected.name} ${selected.surname}`
                    : pickedPlayers?.length !== 0
                    ? `${team}: ${pickedPlayers.length} vybraných hráčů na ledě`
                    : `Střely ${team}: ${filter}`}
            </h3>

            <div className="ShotsMap-container">
                <img src={require("../../Assets/hriste.png")} alt="" />
                {filteredData.map((shot, index) => {
                    const position = calculateShotPosition(shot.x, shot.y, 360);
                    return (
                        <Shot
                            key={index}
                            x={position.x}
                            y={position.y}
                            isGoal={shot.result === "goal"}
                        />
                    );
                })}
            </div>
            <div className="ShotsMap-buttons">
                <Buttons
                    color="black"
                    type="outlined"
                    title={`Detail střel ${team}`}
                    onClick={handleShotsDetail}
                />
                <Buttons
                    color="black"
                    type="outlined"
                    title={`Výběr hráčů ${team}`}
                    onClick={handlePlayerPicker}
                />
            </div>
            {pickedPlayers && (
                <div className="ShotsMap-pickedPlayers">
                    {pickedPlayers.map((player, index) => {
                        const shots = filterShotsByPlayerId(data, player.id);
                        const goals = filterShotsByType(shots, "goal");
                        return (
                            <PickedPlayer
                                key={index}
                                jersey={player.jersey}
                                firstName={player.name}
                                lastName={player.surname}
                                shots={shots.length}
                                goals={goals.length}
                                active={player.id === selectedPlayer}
                                onClick={() => handleSelectPlayer(player.id)}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ShotsMap;

import { IVideoResponse, IVideoTimeResponse } from "../Types";
import { axiosInstance } from "./axiosInstance";

export const getVideoUrl = async (
    token: string,
    matchId: string,
    time: number
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const response: IVideoResponse = await axiosInstance.get(
        `/video/${matchId}/url?seekTime=${time}`,
        {
            headers,
        }
    );
    return response;
};

export const getVideoTime = async (
    token: string,
    matchId: string,
    time: number
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const response: IVideoTimeResponse = await axiosInstance.get(
        `/video/${matchId}/videoTime/${time}`,
        {
            headers,
        }
    );
    return response;
};

export interface IGetVideoPlayerParams {
    token: string;
    matchId: string;
    time?: number;
    text?: string;
    description?: string;
    videoType?: "full" | "segment";
    videoStartTime?: number;
    videoEndTime?: number;
    extraControls?: boolean;
}

export const getVideoPlayer = async ({
    token,
    matchId,
    time,
    text,
    description,
    videoType,
    videoStartTime,
    videoEndTime,
    extraControls,
}: IGetVideoPlayerParams) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const response = await axiosInstance.get(`/video/${matchId}/player`, {
        headers,
        params: {
            seekTime: time,
            text,
            description,
            videoType,
            videoStartTime,
            videoEndTime,
            extraControls,
        },
    });
    return response;
};

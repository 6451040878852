import { FC, useState } from "react";
import { IGoal } from "../../Types";
import { getGoalPosition, getPlayerById, getPlayerTeam } from "../../Utils";
import { useAppSelector } from "../../Redux/store";
import { useClickOutside } from "../../Hooks";
import ShotsPopup from "../04-Micros/ShotsPopup";

interface IProps {
    goal: IGoal;
    width: number;
    thirds: number;
}

const MomentumGoal: FC<IProps> = ({ goal, width, thirds }) => {
    const match = useAppSelector((state) => state.match.value);

    const [popup, setPopup] = useState<boolean>(false);

    const ref = useClickOutside<HTMLDivElement>(() => {
        setPopup(false);
    }, []);

    const handleClick = () => {
        setPopup(true);
    };

    return (
        <div
            ref={ref}
            className="MomentumGoal"
            style={{ ...getGoalPosition(goal.time, width, thirds) }}
            onClick={handleClick}
        >
            {popup && (
                <ShotsPopup
                    {...getPlayerById(
                        goal.playerId,
                        match[
                            getPlayerTeam(goal.playerId, match) === "home"
                                ? "homeTeam"
                                : "awayTeam"
                        ].roster
                    )!}
                    time={goal.time}
                    compensateTop={8}
                    compensateLeft={-14}
                />
            )}
            <div
                className={`MomentumGoal-inner ${getPlayerTeam(
                    goal.playerId,
                    match
                )}`}
            >
                G
            </div>
        </div>
    );
};

export default MomentumGoal;

import { useEffect, useState } from "react";

import { useAppSelector } from "../../Redux/store";
import {
    IFaceOff,
    IFaceOffLocation,
    IFaceOffPlayer,
    IMatch,
} from "../../Types";
import {
    filterByThirds,
    filterFaceOff,
    getTeamFaceOffWithShot,
    getThirdsFilters,
    prepareFaceOffCountStats,
    prepareFaceOffMapData,
    prepareFaceOffSmallStats,
    prepareTeamFaceOffPlayers,
} from "../../Utils";
import FaceOffMap from "../03-Partials/FaceOffMap";
import FiltersContainer from "../03-Partials/FiltersContainer";
import StatsBox from "../03-Partials/StatsBox";
import StatsBoxSimple from "../03-Partials/StatsBoxSimple";
import StatsDetailModal from "../03-Partials/StatsDetailModal";
import Buttons from "../04-Micros/Buttons";
import FiltersRow from "../04-Micros/FiltersRow";
import Loading from "../04-Micros/Loading";
import SelectTab from "../04-Micros/SelectTab";
import VideoModal from "../03-Partials/VideoModal";

const FaceOff = () => {
    const match = useAppSelector((state) => state.match.value);

    const [filteredMatch, setFilteredMatch] = useState<IMatch>();
    const [filteredFaceOffs, setFilteredFaceOffs] = useState<IFaceOff[]>([]);
    const [thirdsActive, setThirdsActive] = useState<string>("");

    const [selectedHome, setSelectedHome] = useState<string>("");
    const [selectedAway, setSelectedAway] = useState<string>("");
    const [selectedPart, setSelectedPart] = useState<string>("");

    const [home, setHome] = useState<IFaceOffPlayer[]>([]);
    const [away, setAway] = useState<IFaceOffPlayer[]>([]);
    const [homeWithShot, setHomeWithShot] = useState<IFaceOff[]>([]);
    const [awayWithShot, setAwayWithShot] = useState<IFaceOff[]>([]);

    const homeAttackZone: IFaceOffLocation[] = ["ADR", "ADL"];
    const awayAttackZone: IFaceOffLocation[] = ["HDR", "HDL"];

    useEffect(() => {
        let tmp = match;

        if (thirdsActive !== "All") {
            tmp = filterByThirds(tmp, thirdsActive);
        }
        setFilteredMatch(tmp);
    }, [thirdsActive, match]);

    useEffect(() => {
        if (filteredMatch) {
            const faceOffs = filterFaceOff(
                selectedHome,
                selectedAway,
                selectedPart,
                filteredMatch.faceOff
            );
            setFilteredFaceOffs(faceOffs);
            setHome(
                prepareTeamFaceOffPlayers(
                    filteredMatch.homeTeam.roster,
                    faceOffs
                )
            );
            setAway(
                prepareTeamFaceOffPlayers(
                    filteredMatch.awayTeam.roster,
                    faceOffs
                )
            );
            setHomeWithShot(
                getTeamFaceOffWithShot(
                    filteredMatch.homeTeam.roster,
                    faceOffs,
                    filteredMatch.shot,
                    homeAttackZone
                )
            );
            setAwayWithShot(
                getTeamFaceOffWithShot(
                    filteredMatch.awayTeam.roster,
                    faceOffs,
                    filteredMatch.shot,
                    awayAttackZone
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredMatch, selectedPart, selectedHome, selectedAway]);

    const selectPlayer = (team: "home" | "away", playerId: string) => {
        if (team === "home") {
            if (selectedHome === playerId) setSelectedHome("");
            else setSelectedHome(playerId);
        } else {
            if (selectedAway === playerId) setSelectedAway("");
            else setSelectedAway(playerId);
        }
    };

    const selectionActive = selectedHome || selectedAway || selectedPart;

    const cancelSelection = () => {
        setSelectedHome("");
        setSelectedAway("");
        setSelectedPart("");
    };

    return (
        <>
            <FiltersContainer>
                <FiltersRow
                    filters={getThirdsFilters(match.length, match.game)}
                    active={thirdsActive}
                    setActive={setThirdsActive}
                />
            </FiltersContainer>
            <main className="FaceOff">
                {!filteredMatch ? (
                    <Loading />
                ) : (
                    <>
                        <div className="FaceOff-stats">
                            <StatsBox
                                {...prepareFaceOffCountStats(
                                    filteredMatch,
                                    filteredFaceOffs
                                )}
                            />
                            <div className="FaceOff-stats--simple">
                                {prepareFaceOffSmallStats(
                                    filteredMatch,
                                    filteredFaceOffs
                                ).map((stat, index) => (
                                    <StatsBoxSimple
                                        key={index}
                                        {...stat}
                                        disabled={
                                            stat.homeStats === 0 &&
                                            stat.awayStats === 0
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="FaceOff-body">
                            <div className="FaceOff-body--players">
                                <SelectTab
                                    valueLeft="Se střelou"
                                    valueRight={homeWithShot.length}
                                    disabled
                                    main
                                />
                                {home.map((player) => (
                                    <SelectTab
                                        key={player.id}
                                        valueLeft={`${player.jersey} ${player.surname}`}
                                        valueRight={`${player.wins}:${player.loses} (${player.percent})`}
                                        active={selectedHome === player.id}
                                        onClick={() =>
                                            selectPlayer("home", player.id)
                                        }
                                    />
                                ))}
                            </div>
                            <FaceOffMap
                                {...prepareFaceOffMapData(
                                    home,
                                    away,
                                    filterFaceOff(
                                        selectedHome,
                                        selectedAway,
                                        selectedPart,
                                        filteredMatch.faceOff
                                    )
                                )}
                                homeTeam={filteredMatch.homeTeam.shortcut}
                                awayteam={filteredMatch.awayTeam.shortcut}
                                selectedPart={selectedPart}
                                setSelectedPart={setSelectedPart}
                            />
                            <div className="FaceOff-body--players">
                                <SelectTab
                                    valueLeft="Se střelou"
                                    valueRight={awayWithShot.length}
                                    disabled
                                    main
                                />
                                {away.map((player) => (
                                    <SelectTab
                                        key={player.id}
                                        valueLeft={`${player.jersey} ${player.surname}`}
                                        valueRight={`${player.wins}:${player.loses} (${player.percent})`}
                                        active={selectedAway === player.id}
                                        onClick={() =>
                                            selectPlayer("away", player.id)
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        {selectionActive && (
                            <Buttons
                                title="Zrušit výběr"
                                color="black"
                                type="outlined"
                                maxWidth={228}
                                onClick={cancelSelection}
                            />
                        )}
                    </>
                )}
            </main>
            <StatsDetailModal />
            <VideoModal />
        </>
    );
};

export default FaceOff;

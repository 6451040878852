import { useEffect, useState } from "react";
import { useAppSelector } from "../Redux/store";
import { IIceTimeOptions, IIceTimePlayer, IMatch } from "../Types";
import { getHomeToAway, getAwayToHome } from "../Api";
import { comparePlayersByLineup, prepareIceTimeData } from "../Utils";

export const useGetHeadToHead = (
    match: IMatch,
    options: IIceTimeOptions,
    comparePlayers: (
        a: IIceTimePlayer,
        b: IIceTimePlayer
    ) => number = comparePlayersByLineup
) => {
    const token = useAppSelector((state) => state.login.token.access_token);
    const [home, setHome] = useState<IIceTimePlayer[]>([]);
    const [away, setAway] = useState<IIceTimePlayer[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        if (token) {
            getHomeToAway(
                token,
                match.id,
                options.start,
                options.end,
                options.gameState
            )
                .then((res) => {
                    setHome(
                        prepareIceTimeData(
                            res.data,
                            match.homeTeam.roster,
                            match.awayTeam.roster,
                            "home"
                        )
                    );
                })
                .catch((err) => console.log("Get home-to-away err:", err));

            getAwayToHome(
                token,
                match.id,
                options.start,
                options.end,
                options.gameState
            )
                .then((res) => {
                    setAway(
                        prepareIceTimeData(
                            res.data,
                            match.homeTeam.roster,
                            match.awayTeam.roster,
                            "away"
                        )
                    );
                })
                .catch((err) => console.log("Get away-to-home err:", err));
        }
    }, [match, token, options]);

    useEffect(() => {
        if (home.length && away.length) {
            setIsLoading(false);
        }
    }, [home, away]);

    return {
        home: home.sort(comparePlayers),
        away: away.sort(comparePlayers),
        isLoading,
    };
};

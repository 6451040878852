//Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//Types
import { IIdentity, TToken } from "../Types/login";
import { setToSessionWithExpiration } from "../Utils";

interface ILogin {
    token: TToken;
    identity: IIdentity;
    logged: boolean;
}

const loginInitialState: ILogin = {
    token: {
        access_token: "",
        expires_in: 0,
        scope: null,
        token_type: "",
    },
    identity: {
        id: "",
        name: "",
        surname: "",
        role: "",
        teamId: null,
    },
    logged: false,
};

export const loginSlice = createSlice({
    name: "login",
    initialState: loginInitialState,
    reducers: {
        setToken: (state, action: PayloadAction<TToken>) => {
            state.token = action.payload;
            state.logged = true;
            setToSessionWithExpiration(
                action.payload.access_token,
                action.payload.expires_in,
                "token"
            );
        },
        setIdentity: (state, action: PayloadAction<IIdentity>) => {
            state.identity = action.payload;
        },
        setSessionToken: (state, action: PayloadAction<string>) => {
            state.token.access_token = action.payload;
            state.logged = true;
        },
        setLogout: (state) => {
            return loginInitialState;
        },
    },
});

export const { setToken, setIdentity, setSessionToken, setLogout } =
    loginSlice.actions;
export default loginSlice.reducer;

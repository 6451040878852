import { FC } from "react";

interface IProps {
    active: boolean;
    onClick: () => void;
    title?: string;
    disabled?: boolean;
    paddingHorizontal?: number;
    paddingVertical?: number;
    additionalClass?: string;
}

const Filter: FC<IProps> = ({
    title,
    active,
    disabled,
    onClick,
    paddingHorizontal = 8,
    paddingVertical = 8,
    additionalClass,
}) => {
    return (
        <div
            className={`Filter${
                additionalClass ? " Filter--" + additionalClass : ""
            }${active ? " active" : ""}${disabled ? " disabled" : ""}`}
            onClick={onClick}
            style={{
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
                paddingTop: paddingVertical,
                paddingBottom: paddingVertical,
                visibility: title !== undefined ? "unset" : "hidden",
            }}
        >
            <p>{title}</p>
        </div>
    );
};

export default Filter;

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../Redux/store";
import { getMatchDetails } from "../Api";
import { IMatch } from "../Types";
import { setMatch } from "../Redux/match";

export const useGetMatchDetail = (matchId: string, current: boolean, onRender: boolean) => {
	const dispatch = useAppDispatch();
	const token = useAppSelector((state) => state.login.token.access_token);
	const [currentMatch, setCurrentMatch] = useState<IMatch>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (onRender) {
			handleLoadData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, onRender, matchId]);

	const handleLoadData = () => {
		setIsLoading(true);
		getMatchDetails(token, matchId)
			.then((res) => {
				setCurrentMatch(res.data);
				if (current) {
					dispatch(setMatch(res.data));
				}
				setIsLoading(false);
			})
			.catch((err) => {
				if (err.response.status !== 404) {
					// 404 means that match is not active now
					console.log("Get match detail err:", err);
				}
			});
	};

	return { currentMatch, isLoading, handleLoadData };
};

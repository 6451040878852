import { FC, useEffect, useRef, useState } from "react";
import { IMomentum } from "../../Types";
import { useAppSelector } from "../../Redux/store";
import MomentumBar from "../03-Partials/MomentumBar";
import { WhistleGreenIcon, WhistleRedIcon, WhistleGrayIcon } from "../../Icons";
import {
    filterGameStates,
    getColorOnTimeline,
    getMaxMomentum,
    getMomentumThirds,
    getMomentumTimeline,
    getStatePosition,
} from "../../Utils";
import MomentumGoal from "../03-Partials/MomentumGoal";

interface IProps {
    homeTeam: string;
    awayTeam: string;
    momentum: IMomentum[];
}

const MomentumChart: FC<IProps> = ({ homeTeam, awayTeam, momentum }) => {
    const match = useAppSelector((state) => state.match.value);
    const identity = useAppSelector((state) => state.login.identity);
    const awayId = useAppSelector((state) => state.match.value.awayTeam.id);

    const [headerWidth, setTimelineWidth] = useState<number>(0);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (headerRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                if (headerRef.current!.offsetWidth !== headerWidth) {
                    setTimelineWidth(headerRef.current!.offsetWidth);
                }
            });

            resizeObserver.observe(headerRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerRef.current]);

    const gameStates =
        match[
            identity.teamId === awayId ? "homeTeam" : "awayTeam"
        ].gameState.filter(filterGameStates);

    return (
        <div className="MomentumCharts-container">
            <div
                className="MomentumCharts-container--team home"
                style={{ top: 40 }}
            >
                {identity.teamId === awayId ? awayTeam : homeTeam}
            </div>
            <div
                className="MomentumCharts-container--team away"
                style={{ bottom: momentum.length > 120 ? 50 : 34 }}
            >
                {identity.teamId === awayId ? homeTeam : awayTeam}
            </div>
            <div className="MomentumCharts-scroll">
                <div className="MomentumCharts">
                    <div ref={headerRef} className="MomentumCharts-header">
                        {gameStates.map((state, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        ...getStatePosition(
                                            state,
                                            headerWidth,
                                            getMomentumThirds(momentum.length)
                                        ),
                                    }}
                                    className="MomentumCharts-header--state"
                                >
                                    {state.type === "PP" && (
                                        <WhistleGreenIcon />
                                    )}
                                    {state.type === "SH" && <WhistleRedIcon />}
                                    {state.type === "RG" && <WhistleGrayIcon />}
                                </div>
                            );
                        })}
                    </div>
                    <div className="MomentumCharts-chart">
                        {gameStates.map((state, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        ...getStatePosition(
                                            state,
                                            headerWidth,
                                            getMomentumThirds(momentum.length)
                                        ),
                                        ...getColorOnTimeline(state),
                                    }}
                                    className="MomentumCharts-chart--state"
                                ></div>
                            );
                        })}

                        <div className="MomentumCharts-chart--bars">
                            {[
                                ...Array(
                                    getMomentumThirds(momentum.length) * 40
                                ),
                            ].map((e, index) => (
                                <MomentumBar
                                    momentum={
                                        momentum.length > index
                                            ? momentum[index]
                                            : undefined
                                    }
                                    descending={
                                        index > 0 &&
                                        index < momentum.length &&
                                        Math.abs(momentum[index - 1].momentum) >
                                            Math.abs(momentum[index].momentum)
                                    }
                                    max={getMaxMomentum(momentum)}
                                    key={index}
                                />
                            ))}
                        </div>

                        {match.goal.map((goal, index) => (
                            <MomentumGoal
                                goal={goal}
                                width={headerWidth}
                                thirds={getMomentumThirds(momentum.length)}
                                key={index}
                            />
                        ))}
                    </div>
                    <div className="MomentumCharts-footer">
                        {getMomentumTimeline(momentum.length).map(
                            (item, index) => (
                                <p key={index}>{item}</p>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MomentumChart;

import { FC } from "react";

interface IShotProps {
    x: number;
    y: number;
    isGoal?: boolean;
}

const Shot: FC<IShotProps> = ({ x, y, isGoal = false }) => {
    return (
        <div
            className={`ShotsMap-shot${isGoal ? " goal" : ""}`}
            style={{ top: y, left: x }}
        ></div>
    );
};

export default Shot;
